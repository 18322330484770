export default {
  async setSearchOpen({ commit }, payload) {
    return commit('SET_SEARCH_OPEN', payload)
  },

  async setSidebarOpen({ commit }, payload) {
    return commit('SET_SIDEBAR_OPEN', payload)
  },

  async setSidebarOpenProfile({ commit }, payload) {
    return commit('SET_SIDEBAR_OPEN_PROFILE', payload)
  },

  async setAlertInstability({ commit }, payload) {
    return commit('SET_ALERT_INSTABILITY', payload)
  },
}
