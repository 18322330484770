import abTestIntegration from '~/store/services/ABTest'
import articlesIntegration from '~/store/services/Articles'
import csatIntegration from '~/store/services/CSAT'
import categoriesIntegration from '~/store/services/Categories'
import changeMailIntegration from '~/store/services/Changemail'
import DatahubIntegration from '~/store/services/Datahub'
import faqgptIntegration from '~/store/services/FAQGPTIntegration'
import FormIntegration from '~/store/services/Form'
import sectionIntegration from '~/store/services/Section'
import ToggleIntegration from '~/store/services/Toggle'
import UserIntegration from '~/store/services/User'

export default (ctx, inject) => {
  // user
  const userPatch = UserIntegration(ctx.$axios)
  inject('user', userPatch())

  // AB Test
  const abtestPath = abTestIntegration(ctx)
  inject('abtest', abtestPath())

  // Articles
  const articlesPatch = articlesIntegration(ctx.$axios)
  inject('articles', articlesPatch())

  //Categories
  const categoriesPatch = categoriesIntegration(ctx.$axios)
  inject('categories', categoriesPatch())

  //CSAT
  const csatPatch = csatIntegration(ctx)
  inject('csat', csatPatch())

  //Changemail
  const changemailPatch = changeMailIntegration(ctx)
  inject('changemail', changemailPatch())

  //Section
  const sectionPatch = sectionIntegration(ctx.$axios)
  inject('section', sectionPatch())

  //Form
  const formPatch = FormIntegration(ctx.$axios)
  inject('forms', formPatch())

  //Toggle
  const togglePatch = ToggleIntegration(ctx)
  inject('toggle', togglePatch())

  //api-faqgpt-integration
  const faqgptIntegrationPatch = faqgptIntegration(ctx)
  inject('faqgptIntegration', faqgptIntegrationPatch())

  //Datahub
  const datahubPatch = DatahubIntegration(ctx)
  inject('datahub', datahubPatch())
}
