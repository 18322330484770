export const LANGUAGE = {
  ENGLISH: 'en',
  SPANISH: 'es',
  PORTUGUESE: 'pt-br',
  FRENCH: 'fr',
  DEFAULT: 'x-default',
}

export const DEFAULT_LANGUAGE = LANGUAGE.ENGLISH

export const FALLBACK_LANGUAGE = LANGUAGE.ENGLISH
