import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _35913f7c = () => interopDefault(import('../pages/return_to.vue' /* webpackChunkName: "pages/return_to" */))
const _5057b9a3 = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _28a311a6 = () => interopDefault(import('../pages/auth/logout/index.vue' /* webpackChunkName: "pages/auth/logout/index" */))
const _3bab37c7 = () => interopDefault(import('../pages/auth/renew/index.vue' /* webpackChunkName: "pages/auth/renew/index" */))
const _4b294395 = () => interopDefault(import('../pages/auth/login/login.js' /* webpackChunkName: "pages/auth/login/login" */))
const _05eda12e = () => interopDefault(import('../pages/auth/logout/logout.js' /* webpackChunkName: "pages/auth/logout/logout" */))
const _2821d34d = () => interopDefault(import('../pages/auth/renew/renew.js' /* webpackChunkName: "pages/auth/renew/renew" */))
const _32a5f79e = () => interopDefault(import('../pages/hc/_lang/_page/_slug.vue' /* webpackChunkName: "pages/hc/_lang/_page/_slug" */))
const _7afccc4e = () => interopDefault(import('../pages/_locale/index.vue' /* webpackChunkName: "pages/_locale/index" */))
const _d1c2d7c4 = () => interopDefault(import('../pages/_locale/email-validate/index.vue' /* webpackChunkName: "pages/_locale/email-validate/index" */))
const _5ae66c53 = () => interopDefault(import('../pages/_locale/redirectZendesk.js' /* webpackChunkName: "pages/_locale/redirectZendesk" */))
const _8bd649a0 = () => interopDefault(import('../pages/_locale/script.js' /* webpackChunkName: "pages/_locale/script" */))
const _107a885f = () => interopDefault(import('../pages/_locale/search/index.vue' /* webpackChunkName: "pages/_locale/search/index" */))
const _02a554fe = () => interopDefault(import('../pages/_locale/contact-us/ContactUs.js' /* webpackChunkName: "pages/_locale/contact-us/ContactUs" */))
const _4d0d97d8 = () => interopDefault(import('../pages/_locale/email-validate/constants.js' /* webpackChunkName: "pages/_locale/email-validate/constants" */))
const _221c1924 = () => interopDefault(import('../pages/_locale/search/Search.js' /* webpackChunkName: "pages/_locale/search/Search" */))
const _eb8b3328 = () => interopDefault(import('../pages/_locale/articles/_slug.vue' /* webpackChunkName: "pages/_locale/articles/_slug" */))
const _4217db1a = () => interopDefault(import('../pages/_locale/contact-us/_slug.vue' /* webpackChunkName: "pages/_locale/contact-us/_slug" */))
const _64d2119a = () => interopDefault(import('../pages/_locale/requests/_slug.vue' /* webpackChunkName: "pages/_locale/requests/_slug" */))
const _fcd70dc6 = () => interopDefault(import('../pages/_locale/sections/_slug.vue' /* webpackChunkName: "pages/_locale/sections/_slug" */))
const _8e6210aa = () => interopDefault(import('../pages/_locale/article/_id/Article.js' /* webpackChunkName: "pages/_locale/article/_id/Article" */))
const _76e8250f = () => interopDefault(import('../pages/_locale/categories/_id/Categories.js' /* webpackChunkName: "pages/_locale/categories/_id/Categories" */))
const _eacac4e6 = () => interopDefault(import('../pages/_locale/section/_id/Section.js' /* webpackChunkName: "pages/_locale/section/_id/Section" */))
const _70b794d0 = () => interopDefault(import('../pages/_locale/article/_id/_slug.vue' /* webpackChunkName: "pages/_locale/article/_id/_slug" */))
const _a4f8dc14 = () => interopDefault(import('../pages/_locale/categories/_id/_slug.vue' /* webpackChunkName: "pages/_locale/categories/_id/_slug" */))
const _62895947 = () => interopDefault(import('../pages/_locale/section/_id/_slug.vue' /* webpackChunkName: "pages/_locale/section/_id/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/return_to",
    component: _35913f7c,
    name: "return_to"
  }, {
    path: "/auth/login",
    component: _5057b9a3,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _28a311a6,
    name: "auth-logout"
  }, {
    path: "/auth/renew",
    component: _3bab37c7,
    name: "auth-renew"
  }, {
    path: "/auth/login/login",
    component: _4b294395,
    name: "auth-login-login"
  }, {
    path: "/auth/logout/logout",
    component: _05eda12e,
    name: "auth-logout-logout"
  }, {
    path: "/auth/renew/renew",
    component: _2821d34d,
    name: "auth-renew-renew"
  }, {
    path: "/hc/:lang?/:page?/:slug?",
    component: _32a5f79e,
    name: "hc-lang-page-slug"
  }, {
    path: "/:locale",
    component: _7afccc4e,
    name: "locale"
  }, {
    path: "/:locale?/email-validate",
    component: _d1c2d7c4,
    name: "locale-email-validate"
  }, {
    path: "/:locale?/redirectZendesk",
    component: _5ae66c53,
    name: "locale-redirectZendesk"
  }, {
    path: "/:locale?/script",
    component: _8bd649a0,
    name: "locale-script"
  }, {
    path: "/:locale?/search",
    component: _107a885f,
    name: "locale-search"
  }, {
    path: "/:locale?/contact-us/ContactUs",
    component: _02a554fe,
    name: "locale-contact-us-ContactUs"
  }, {
    path: "/:locale?/email-validate/constants",
    component: _4d0d97d8,
    name: "locale-email-validate-constants"
  }, {
    path: "/:locale?/search/Search",
    component: _221c1924,
    name: "locale-search-Search"
  }, {
    path: "/:locale?/articles/:slug?",
    component: _eb8b3328,
    name: "locale-articles-slug"
  }, {
    path: "/:locale?/contact-us/:slug?",
    component: _4217db1a,
    name: "locale-contact-us-slug"
  }, {
    path: "/:locale?/requests/:slug?",
    component: _64d2119a,
    name: "locale-requests-slug"
  }, {
    path: "/:locale?/sections/:slug?",
    component: _fcd70dc6,
    name: "locale-sections-slug"
  }, {
    path: "/:locale?/article/:id?/Article",
    component: _8e6210aa,
    name: "locale-article-id-Article"
  }, {
    path: "/:locale?/categories/:id?/Categories",
    component: _76e8250f,
    name: "locale-categories-id-Categories"
  }, {
    path: "/:locale?/section/:id?/Section",
    component: _eacac4e6,
    name: "locale-section-id-Section"
  }, {
    path: "/:locale?/article/:id?/:slug?",
    component: _70b794d0,
    name: "locale-article-id-slug"
  }, {
    path: "/:locale?/categories/:id?/:slug?",
    component: _a4f8dc14,
    name: "locale-categories-id-slug"
  }, {
    path: "/:locale?/section/:id?/:slug?",
    component: _62895947,
    name: "locale-section-id-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
