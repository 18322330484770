import { SPECIFICATION } from '~/enum/form/specification'
import { SUBJECT } from '~/enum/form/subject'
import runtime from '../config/runtime'
import { SLUG } from '../enum/form/constants'
import { LANGUAGE } from '../enum/language'
import { formOptions } from '../enum/options'

export const convertToSlug = text => {
  let locale = ''
  let slug = ''
  if (typeof window !== 'undefined') {
    locale = window.location.pathname.split('/')[1]
  }
  if (text) {
    if (![LANGUAGE.ARABIC].includes(locale)) {
      slug = convertLatinAlphabet(text)
    } else {
      slug = convertArabicAlphabet(text)
    }
  }

  return slug
}

export const mountURL = (segment, id, title, locale) => {
  const ID_SLUG = id ? `/${id}` : ''
  const TITLE_SLUG = title ? `/${convertToSlug(title)}` : ''
  return `/${locale}/${segment}${ID_SLUG}${TITLE_SLUG}`
}

const convertLatinAlphabet = text => {
  const tone = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
  const replacementTone = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
  const reg = new RegExp(tone.split('').join('|'), 'g')
  const slug = text
    .toString()
    .toLowerCase()
    .trim()
    .replace(reg, sub => replacementTone.charAt(tone.indexOf(sub)))
    .replace(/&/g, '-and-')
    .replace(/[\s\W-]+/g, '-')

  return slug
}

const convertArabicAlphabet = text => {
  const tone = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:; '
  const replacementTone = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
  const reg = new RegExp(tone.split('').join('|'), 'g')
  const slug = text
    .toString()
    .toLowerCase()
    .trim()
    .replace(reg, sub => replacementTone.charAt(tone.indexOf(sub)))
    .replace(/&/g, '-and-')

  return slug
}

export const availableLocale = locale => {
  return /^pt\-br|es|en|fr+$/.test(locale)
}

export const alternatives = fullPath => {
  const baseUrl = (runtime.publicRuntimeConfig.baseUrl || '').replace('www.', '')

  const { pathname, search } = new URL(baseUrl + fullPath)
  const currentLocale = pathname.split('/')[1]

  const locales = Object.values(LANGUAGE)
  let localizedUrls = { 'pt-br': '', en: '', es: '', fr: '' }

  locales.forEach(locale => {
    localizedUrls[locale] = `${baseUrl}${pathname.replace(currentLocale, locale)}${search}`
  })

  return localizedUrls
}

export const localeConversion = localeUser => {
  const obj = {
    PT_BR: {
      languageSelect: 'portuguesBr',
      country: 'country_brazil',
    },
    EN: {
      languageSelect: 'ingles',
      country: 'country_united_states',
    },
    ES: {
      languageSelect: 'espanhol',
      country: 'country_spain',
    },
    FR: {
      languageSelect: 'frances',
      country: 'country_france',
    },
  }
  const { languageSelect = '', country = '' } = obj[localeUser] || obj.EN

  return { languageSelect, country }
}

export const slugOfReason = reason => {
  let slugForm = ''
  formOptions.map(key => {
    if (key.reason === reason) {
      return (slugForm = key.slug)
    }
  })

  return slugForm
}

export const convertNameAvatar = name => {
  let firstName = name?.split(' ')[0]
  let amountName = name?.split(' ').length
  let lastName = name?.split(' ')[amountName - 1]
  let nameAvatar = firstName?.substr(0, 1) + lastName?.substr(0, 1)

  return { nameAvatar, firstName }
}

export const hasInLocalStorage = key => window?.localStorage?.hasOwnProperty(key)

export const getFromLocalStorage = key => JSON.parse(window?.localStorage?.getItem(key) ?? '{}')

export const getSubjectKeyBySlug = slug => {
  const subject = Object.values(SLUG).find(value => value === slug)

  switch (subject) {
    case SLUG.BOUGHT_AND_NEED_HELP:
      return 'ticket.reasons.purchase'
    case SLUG.IM_A_PRODUCER_AND_I_NEED_HELP:
      return 'ticket.reasons.producer'
    case SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP:
      return 'ticket.reasons.affiliate'
    case SLUG.ABOUT_HOTMART:
      return 'ticket.reasons.aboutHotmart'
    case SLUG.HOTMART_EXTENSIONS:
      return 'ticket.reasons.extensions'
    default:
      throw new Error(`Invalid subject slug: ${slug}`)
  }
}

export const getReasonKeyBySlug = slug => {
  const reason = Object.values(SUBJECT).find(item => item.slug === slug)

  if (!reason) {
    throw new Error(`Invalid reason slug: ${slug}`)
  }

  return `ticket.subject.options.${reason.key}`
}

export const getSpecificationKeyBySlug = slug => {
  const specification = Object.values(SPECIFICATION).find(item => item.slug === slug)

  if (!specification) {
    throw new Error(`Invalid specification slug: ${slug}`)
  }

  return `ticket.subject.specifications.options.${specification.key}`
}
