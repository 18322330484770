import { LABEL_SUBJECT, SUBJECT_TAG } from './constants'
import { SPECIFICATION } from './specification'

export const SUBJECT = {
  BUYER_PHYSICAL_PRODUCTS: {
    key: 'buyerPhysicalProducts',
    tag: SUBJECT_TAG.BUYER_PHYSICAL_PRODUCTS,
    slug: 'buyer-physical-products',
    specifications: [
      SPECIFICATION.PHYSICAL_PRODUCTS_TRACK_MY_ORDER,
      SPECIFICATION.PHYSICAL_PRODUCTS_ORDER_DIDNT_ARRIVE,
      SPECIFICATION.PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS,
      SPECIFICATION.PHYSICAL_PRODUCTS_MORE_INFORMATION_ABOUT_THE_ORDER,
    ],
  },
  HOTMART_ACCOUNT_ACCESS: {
    key: LABEL_SUBJECT.HOTMART_ACCOUNT_ACCESS,
    tag: SUBJECT_TAG.HOTMART_ACCOUNT_ACCESS,
    slug: 'access-buyer',
  },
  HOTMART_COURSE_ACCESS: {
    key: LABEL_SUBJECT.HOTMART_COURSE_ACCESS,
    tag: SUBJECT_TAG.HOTMART_COURSE_ACCESS,
    slug: 'access-course',
    specifications: [
      SPECIFICATION.CANT_ACCESS_ACCOUNT,
      SPECIFICATION.KNOW_ACCESS_MYCOURSE,
      SPECIFICATION.NOT_RECEIVEING_EMAILS,
      SPECIFICATION.ERROR_MESSAGE_APPEARED,
      SPECIFICATION.TROUBLE_ACESSING_CONTENT,
      SPECIFICATION.TROUBLE_VIDEO_PLAY,
      SPECIFICATION.MY_PRODUCT_CONTENT_QUESTIONS,
      SPECIFICATION.ACCESS_DEADLINES_QUESTIONS,
      SPECIFICATION.BONUS_OR_PHYSICAL_PRODUCT_QUESTIONS,
      SPECIFICATION.SOCIAL_MEDIA_GROUPS_QUESTIONS,
    ],
  },
  HOTMART_REGISTRATION_ACCESS: {
    key: LABEL_SUBJECT.HOTMART_REGISTRATION_ACCESS,
    tag: SUBJECT_TAG.HOTMART_REGISTRATION_ACCESS,
    slug: 'access-registration',
    specifications: [SPECIFICATION.LOGIN_PROBLEMS, SPECIFICATION.RA_NOT_RECEIVEING_EMAILS, SPECIFICATION.ERROR_MESSAGE],
  },
  HOTMART_EVENT_ACCESS: {
    key: LABEL_SUBJECT.HOTMART_EVENT_ACCESS,
    tag: SUBJECT_TAG.HOTMART_EVENT_ACCESS,
    slug: 'access-event',
    specifications: [SPECIFICATION.TRANSFER_OWNERSHIP, SPECIFICATION.CHECKIN],
  },
  HOTMART_HELP_TV: {
    key: LABEL_SUBJECT.HOTMART_HELP_TV,
    tag: SUBJECT_TAG.HOTMART_HELP_TV,
    slug: 'help-tv',
    specifications: [
      SPECIFICATION.CANT_FIND_APP,
      SPECIFICATION.FIND_OR_ACCESS_COURSES,
      SPECIFICATION.HTV_CANT_ACCESS_ACCOUNT,
      SPECIFICATION.HTV_ERROR_MESSAGE_APPEARED,
    ],
  },
  CHANGE_IN_REGISTRATION_INFORMATION: {
    key: LABEL_SUBJECT.CHANGE_IN_REGISTRATION_INFORMATION,
    tag: SUBJECT_TAG.CHANGE_IN_REGISTRATION_INFORMATION,
    slug: 'change-email-buyer',
    specifications: [
      SPECIFICATION.CHANGING_HOTMART_EMAIL,
      SPECIFICATION.CHANGE_REQUEST_REASON,
      SPECIFICATION.RESET_PASSWORD,
      SPECIFICATION.CHANGE_REGISTRATION_LANGUAGE,
      SPECIFICATION.OTHER_CHANGES,
    ],
  },
  REFUND_OR_CANCELLATION: {
    key: LABEL_SUBJECT.REFUND_OR_CANCELLATION,
    tag: SUBJECT_TAG.REFUND_OR_CANCELLATION,
    slug: 'refund',
    specifications: [
      SPECIFICATION.REQUEST_REFUND,
      SPECIFICATION.TRACK_REFUND,
      SPECIFICATION.CANCEL_REFUND_REQUEST,
      SPECIFICATION.PROOF_REFUND_REQUEST,
    ],
  },
  UNSUBSCRIBE: {
    key: LABEL_SUBJECT.UNSUBSCRIBE,
    tag: SUBJECT_TAG.UNSUBSCRIBE,
    slug: 'unsubscribe',
    specifications: [SPECIFICATION.CANCEL_SUBSCRIPTION, SPECIFICATION.CANCELED_SUBSCRIPTION_STILL_BEING_CHARGED],
  },
  IMPROPER_BILLING: {
    key: LABEL_SUBJECT.IMPROPER_BILLING,
    tag: SUBJECT_TAG.IMPROPER_BILLING,
    slug: 'improper-billing',
    specifications: [SPECIFICATION.REQUEST_UNRECOGNIZED_CHARGE_BUYER, SPECIFICATION.REQUEST_DUPLICATE_BILLING_BUYER],
  },
  CERTIFICATE_EMISSION: {
    key: LABEL_SUBJECT.CERTIFICATE_EMISSION,
    tag: SUBJECT_TAG.CERTIFICATE_EMISSION,
    slug: 'certificte-emission',
    specifications: [],
  },
  NOTE_EMISSION: {
    key: LABEL_SUBJECT.NOTE_EMISSION,
    tag: SUBJECT_TAG.NOTE_EMISSION,
    slug: 'note-emission',
    specifications: [SPECIFICATION.BRAZILIAN_CURRENCIE, SPECIFICATION.OTHER_CURRENCIES],
  },
  PRODUCT_QUESTIONS: {
    key: LABEL_SUBJECT.PRODUCT_QUESTIONS,
    tag: SUBJECT_TAG.PRODUCT_QUESTIONS,
    slug: 'product-questions',
    specifications: [
      SPECIFICATION.MY_PRODUCT_CONTENT,
      SPECIFICATION.ACCESS_DEADLINES,
      SPECIFICATION.BONUS_OR_PHYSICAL_PRODUCT,
      SPECIFICATION.SOCIAL_MEDIA_GROUPS,
    ],
  },
  PAYMENT_INFORMATION: {
    key: LABEL_SUBJECT.PAYMENT_INFORMATION,
    tag: SUBJECT_TAG.PAYMENT_INFORMATION,
    slug: 'payment-information',
    specifications: [
      SPECIFICATION.BILLET_BANKING,
      SPECIFICATION.CREDIT_CARD,
      SPECIFICATION.NOT_FOUND_PAYMENT_METHOD,
      SPECIFICATION.PIX,
      SPECIFICATION.INFO_ABOUT_SUBSCRIPTION_PAYMENT,
      SPECIFICATION.OTHER_PAYMENT_METHOD,
    ],
  },
  CANT_MAKE_PURCHASE: {
    key: LABEL_SUBJECT.CANT_MAKE_PURCHASE,
    tag: SUBJECT_TAG.CANT_MAKE_PURCHASE,
    slug: 'cant-make-purchase',
  },
  BE_PRODUCER_OR_AFFILIATE: {
    key: LABEL_SUBJECT.BE_PRODUCER_OR_AFFILIATE,
    tag: SUBJECT_TAG.BE_PRODUCER_OR_AFFILIATE,
    slug: 'be-producer-or-affiliate',
  },
  HELP_MY_STUDENTS: {
    key: LABEL_SUBJECT.HELP_MY_STUDENTS,
    tag: SUBJECT_TAG.HELP_MY_STUDENTS,
    slug: 'help-my-students',
    specifications: [
      SPECIFICATION.HOTMART_ACCOUNT_ACCESS,
      SPECIFICATION.CHANGE_IN_REGISTRATION_INFORMATION,
      SPECIFICATION.SMART_SUBSCRIPTIONS_PAYMENTS,
      SPECIFICATION.REQUEST_UNSUBSCRIBE_PRODUCER,
      SPECIFICATION.CONTENT_OR_CERTIFICATE,
      SPECIFICATION.PAYMENT_INFORMATION,
      SPECIFICATION.CANT_MAKE_PURCHASE,
      SPECIFICATION.REQUEST_REFUND_PRODUCER,
    ],
  },
  ACCOUNT_MANAGEMENT_USER: {
    key: LABEL_SUBJECT.HOTMART_ACCOUNT_ACCESS,
    tag: SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER,
    slug: 'content-or-certificate',
    specifications: [
      SPECIFICATION.AFFILIATE_PRODUCER_ERROR_MESSAGE_APPEARED,
      SPECIFICATION.AFFILIATE_PRODUCER_ACCOUNT_BLOCKING_AND_REACTIVATION,
      SPECIFICATION.AFFILIATE_PRODUCER_CANT_REGISTER_DOCUMENT,
      SPECIFICATION.AFFILIATE_PRODUCER_CANT_ACCESS_ACCOUNT,
      SPECIFICATION.AFFILIATE_PRODUCER_CANT_RECEIVING_EMAIL_HOTMART,
      SPECIFICATION.AFFILIATE_PRODUCER_EMANCIPATE_MINOR,
    ],
  },
  CHANGING_HOTMART_EMAIL_PRODUCER: {
    key: LABEL_SUBJECT.CHANGING_HOTMART_EMAIL,
    tag: SUBJECT_TAG.CHANGING_HOTMART_EMAIL_PRODUCER,
    slug: 'changing-hotmart-email',
  },
  REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER: {
    key: LABEL_SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA,
    tag: SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER,
    slug: 'registration-and-modification-of-personal-data',
    specifications: [
      SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ,
      SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF,
      SPECIFICATION.CHANGE_REGISTRATION,
      SPECIFICATION.CHANGE_DOCUMENT_CPF_TO_CPF,
      SPECIFICATION.CHANGE_REGISTRATION_COUNTRY,
      SPECIFICATION.CORRECT_REGISTRATION_DOCUMENTS,
      SPECIFICATION.GENERAL_PROBLEMS_WITH_REGISTRATION_DATA,
    ],
  },
  PRODUCT_REVIEW: {
    key: LABEL_SUBJECT.PRODUCT_REVIEW,
    tag: SUBJECT_TAG.PRODUCT_REVIEW,
    slug: 'product-review',
    specifications: [
      SPECIFICATION.DOUBTS,
      SPECIFICATION.UNRELEASED_PRODUCT,
      SPECIFICATION.HELP_REGISTER_PRODUCT,
      SPECIFICATION.BLUEPRINT_ANALYSIS,
      SPECIFICATION.PRODUCT_REVIEW_LAUNCH,
    ],
  },
  BANK_ACCOUNT_REGISTRATION_PRODUCER: {
    key: LABEL_SUBJECT.BANK_ACCOUNT_REGISTRATION,
    tag: SUBJECT_TAG.BANK_ACCOUNT_REGISTRATION_PRODUCER,
    slug: 'bank-account-registration',
  },
  WITHDRAWALS_AND_COMMISSIONS_PRODUCER: {
    key: LABEL_SUBJECT.WITHDRAWALS_AND_COMMISSIONS,
    tag: SUBJECT_TAG.WITHDRAWALS_AND_COMMISSIONS_PRODUCER,
    slug: 'withdrawals-and-commissions',
    specifications: [
      SPECIFICATION.PROBLEMS_WITH_MY_BALANCE,
      SPECIFICATION.I_CANT_WITHDRAW,
      SPECIFICATION.CHANGE_COMMISSION_BETWEEN_AFFILIATES,
    ],
  },
  SETTINGS_AND_TOOLS_PRODUCER: {
    key: LABEL_SUBJECT.SETTINGS_AND_TOOLS,
    tag: SUBJECT_TAG.SETTINGS_AND_TOOLS_PRODUCER,
    slug: 'settings-and-tools',
    specifications: [
      SPECIFICATION.IMPORTING_STUDENTS,
      SPECIFICATION.CHANGE_EVENT_DATE,
      SPECIFICATION.CHANGE_COURSE_FORMAT,
      SPECIFICATION.PRODUCER_MEMBERS_AREA,
      SPECIFICATION.EMPLOYEE_CENTER,
      SPECIFICATION.CREDENTIALS,
      SPECIFICATION.CONTENT_DELIVERY,
      SPECIFICATION.INTEGRATIONS,
      SPECIFICATION.SALES_PAGE,
      SPECIFICATION.PROTECT_CONTENT,
      SPECIFICATION.SELL_PRODUCT_PACKAGES,
    ],
  },
  CHANGING_HOTMART_EMAIL_AFFILIATE: {
    key: LABEL_SUBJECT.CHANGING_HOTMART_EMAIL,
    tag: SUBJECT_TAG.CHANGING_HOTMART_EMAIL_AFFILIATE,
    slug: 'changing-hotmart-email',
  },
  ACCOUNT_MANAGEMENT_USER_AFFILIATE: {
    key: LABEL_SUBJECT.HOTMART_ACCOUNT_ACCESS,
    tag: SUBJECT_TAG.ACCOUNT_MANAGEMENT_USER_AFFILIATE,
    slug: 'hotmart-account-access',
    specifications: [
      SPECIFICATION.AFFILIATE_PRODUCER_ERROR_MESSAGE_APPEARED,
      SPECIFICATION.AFFILIATE_PRODUCER_ACCOUNT_BLOCKING_AND_REACTIVATION,
      SPECIFICATION.AFFILIATE_PRODUCER_CANT_REGISTER_DOCUMENT,
      SPECIFICATION.AFFILIATE_PRODUCER_CANT_ACCESS_ACCOUNT,
      SPECIFICATION.AFFILIATE_PRODUCER_CANT_RECEIVING_EMAIL_HOTMART,
      SPECIFICATION.AFFILIATE_PRODUCER_EMANCIPATE_MINOR,
    ],
  },
  REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE: {
    key: LABEL_SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA,
    tag: SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE,
    slug: 'registration-and-modification-of-personal-data',
    specifications: [
      SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CNPJ,
      SPECIFICATION.CHANGE_DOCUMENT_CNPJ_TO_CPF,
      SPECIFICATION.CHANGE_REGISTRATION,
      SPECIFICATION.CHANGE_DOCUMENT_CPF_TO_CPF,
      SPECIFICATION.CHANGE_REGISTRATION_COUNTRY,
      SPECIFICATION.CORRECT_REGISTRATION_DOCUMENTS,
      SPECIFICATION.GENERAL_PROBLEMS_WITH_REGISTRATION_DATA,
    ],
  },
  BANK_ACCOUNT_REGISTRATION_AFFILIATE: {
    key: LABEL_SUBJECT.BANK_ACCOUNT_REGISTRATION,
    tag: SUBJECT_TAG.BANK_ACCOUNT_REGISTRATION_AFFILIATE,
    slug: 'bank-account-registration',
  },
  WITHDRAWALS_AND_COMMISSIONS_AFFILIATE: {
    key: LABEL_SUBJECT.WITHDRAWALS_AND_COMMISSIONS,
    tag: SUBJECT_TAG.WITHDRAWALS_AND_COMMISSIONS_AFFILIATE,
    slug: 'withdrawals-and-commissions',
    specifications: [
      SPECIFICATION.PROBLEMS_WITH_MY_BALANCE,
      SPECIFICATION.I_CANT_WITHDRAW,
      SPECIFICATION.CHANGE_COMMISSION_BETWEEN_AFFILIATES,
    ],
  },
  VIOLATION_OF_TERMS_AND_POLICIES_OF_USE: {
    key: LABEL_SUBJECT.VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
    tag: SUBJECT_TAG.VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
    slug: 'violation-of-terms-and-policies-of-use',
  },
  VIOLATION_OF_PROPERTY_INTELLECTUAL: {
    key: LABEL_SUBJECT.VIOLATION_OF_PROPERTY_INTELLECTUAL,
    tag: SUBJECT_TAG.VIOLATION_OF_PROPERTY_INTELLECTUAL,
    slug: 'violation-of-property-intellectual',
    specifications: [SPECIFICATION.LEGAL_PLAGIARISM, SPECIFICATION.TRADEMARK_VIOLATION, SPECIFICATION.IMAGE_VIOLATION],
  },
  BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE: {
    key: LABEL_SUBJECT.BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
    tag: SUBJECT_TAG.BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
    slug: 'buyer-violation-of-terms-and-policies-of-use',
  },
  SETTINGS_AND_TOOLS_AFFILIATE: {
    key: LABEL_SUBJECT.SETTINGS_AND_TOOLS,
    tag: SUBJECT_TAG.SETTINGS_AND_TOOLS_AFFILIATE,
    slug: 'settings-and-tools',
    specifications: [SPECIFICATION.EXTERNAL_PLATFORMS, SPECIFICATION.DISCLOSURE_LINKS],
  },
  BUSINESS_STRATEGIES: {
    key: LABEL_SUBJECT.BUSINESS_STRATEGIES,
    tag: SUBJECT_TAG.BUSINESS_STRATEGIES,
    slug: 'business-strategies',
  },
  BUSINESS_STRATEGIES_PRODUCER: {
    key: LABEL_SUBJECT.BUSINESS_STRATEGIES,
    tag: SUBJECT_TAG.BUSINESS_STRATEGIES_PRODUCER,
    slug: 'business-strategies',
    specifications: [
      SPECIFICATION.COPRODUCER,
      SPECIFICATION.MARKETPLACE,
      SPECIFICATION.AFFILIATE_PROGRAM_PRODUCER,
      SPECIFICATION.HOTMART_ONE,
    ],
  },
  HOTMART_RECOGNITIONS_PRODUCER: {
    key: LABEL_SUBJECT.HOTMART_RECOGNITIONS_PRODUCER,
    tag: SUBJECT_TAG.HOTMART_RECOGNITIONS_PRODUCER,
    slug: 'hotmart-recognitions',
  },
  HOTMART_RECOGNITIONS_AFFILIATE: {
    key: LABEL_SUBJECT.HOTMART_RECOGNITIONS_AFFILIATE,
    tag: SUBJECT_TAG.HOTMART_RECOGNITIONS_AFFILIATE,
    slug: 'hotmart-recognitions',
  },
  HOTLINK: {
    key: LABEL_SUBJECT.HOTLINK,
    tag: SUBJECT_TAG.HOTLINK,
    slug: 'hotlink',
  },
  BUY_A_PRODUCT_AT_HOTMART: {
    key: LABEL_SUBJECT.BUY_APRODUCT_AT_HOTMART,
    tag: SUBJECT_TAG.BUY_A_PRODUCT_AT_HOTMART,
    slug: 'buy-aproduct-at-hotmart',
  },
  CREATE_A_PRODUCT_AT_HOTMART: {
    key: LABEL_SUBJECT.CREATE_APRODUCT_AT_HOTMART,
    tag: SUBJECT_TAG.CREATE_A_PRODUCT_AT_HOTMART,
    slug: 'create-aproduct-at-hotmart',
  },
  PROMOTE_A_PRODUCT_AT_HOTMART: {
    key: LABEL_SUBJECT.PROMOTE_APRODUCT_AT_HOTMART,
    tag: SUBJECT_TAG.PROMOTE_A_PRODUCT_AT_HOTMART,
    slug: 'promote-aproduct-at-hotmart',
  },
  KNOW_MORE_ABOUT_HOTMART: {
    key: LABEL_SUBJECT.KNOW_MORE_ABOUT_HOTMART,
    tag: SUBJECT_TAG.KNOW_MORE_ABOUT_HOTMART,
    slug: 'know-more-about-hotmart',
  },
  ACCOUNT_LOCKOUT_DISPUTE: {
    key: LABEL_SUBJECT.ACCOUNT_LOCKOUT_DISPUTE,
    tag: SUBJECT_TAG.ACCOUNT_LOCKOUT_DISPUTE,
    slug: 'account-lockout-dispute',
  },
  CAN_NOT_ACCESS_MY_ACCOUNT: {
    key: LABEL_SUBJECT.CAN_NOT_ACCESS_MY_ACCOUNT,
    tag: SUBJECT_TAG.CAN_NOT_ACCESS_MY_ACCOUNT,
    slug: 'can-not-access-my-account',
  },
  REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA: {
    key: LABEL_SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA,
    tag: SUBJECT_TAG.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA,
    slug: 'registration-and-modification-of-personal-data',
  },
  MANAGE_MY_COMMUNITY: {
    key: LABEL_SUBJECT.MANAGE_MY_COMMUNITY,
    tag: SUBJECT_TAG.MANAGE_MY_COMMUNITY,
    slug: 'manage-my-community',
  },
  SPARKLE_SUBSCRIPTIONS_BONUSES: {
    key: LABEL_SUBJECT.SPARKLE_SUBSCRIPTIONS_BONUSES,
    tag: SUBJECT_TAG.SPARKLE_SUBSCRIPTIONS_BONUSES,
    slug: 'sparkle-subscriptions-bonuses',
  },
  MY_WALLET: { key: LABEL_SUBJECT.MY_WALLET, tag: SUBJECT_TAG.MY_WALLET, slug: 'my-wallet' },
  PROBLEM_WATCHING_SOME_CONTENT: {
    key: LABEL_SUBJECT.PROBLEM_WATCHING_SOME_CONTENT,
    tag: SUBJECT_TAG.PROBLEM_WATCHING_SOME_CONTENT,
    slug: 'problem-watching-some-content',
  },
  REFUND_OF_A_PURCHASE: {
    key: LABEL_SUBJECT.REFUND_OF_APURCHASE,
    tag: SUBJECT_TAG.REFUND_OF_A_PURCHASE,
    slug: 'refund-of-apurchase',
  },
  DELETE_ACCOUNT: { key: LABEL_SUBJECT.DELETE_ACCOUNT, tag: SUBJECT_TAG.DELETE_ACCOUNT, slug: 'delete-account' },
  KNOW_MORE_ABOUT_EXTENSIONS: {
    key: LABEL_SUBJECT.KNOW_MORE_ABOUT_EXTENSIONS,
    tag: SUBJECT_TAG.KNOW_MORE_ABOUT_EXTENSIONS,
    slug: 'know-more-about-extensions',
  },
  TECHNICAL_SUPPORT: {
    key: LABEL_SUBJECT.TECHNICAL_SUPPORT,
    tag: SUBJECT_TAG.TECHNICAL_SUPPORT,
    slug: 'technical-support',
  },
  TOOLS_AFFILIATE: {
    key: LABEL_SUBJECT.TOOLS,
    tag: SUBJECT_TAG.TOOLS_AFFILIATE,
    slug: 'tools',
    specifications: [
      SPECIFICATION.HOTLEADS_AFFILIATE,
      SPECIFICATION.LINK_SHORTENER,
      SPECIFICATION.BONUS_CONTENT,
      SPECIFICATION.TRACKING_PIXEL_AFFILIATE,
      SPECIFICATION.LINK_CHECKOUT_AFFILIATE,
      SPECIFICATION.CHECKOUT_AFFILIATE,
    ],
  },
  TOOLS_PRODUCER: {
    key: LABEL_SUBJECT.TOOLS,
    tag: SUBJECT_TAG.TOOLS_PRODUCER,
    slug: 'tools-producer',
    specifications: [
      SPECIFICATION.STUDENT_REVIEWS,
      SPECIFICATION.HOTLEADS,
      SPECIFICATION.MEMBERS_AREA,
      SPECIFICATION.LISTBOSS,
      SPECIFICATION.HOTLINK,
      SPECIFICATION.PROMOTIONAL_MATERIAL,
      SPECIFICATION.TRACKING_PIXEL,
    ],
  },
  CONFIRM_EXISTENCE_PERSONAL_DATA_PROCESSING: {
    key: LABEL_SUBJECT.CONFIRM_EXISTENCE_PERSONAL_DATA_PROCESSING,
    tag: SUBJECT_TAG.CONFIRM_EXISTENCE_PERSONAL_DATA_PROCESSING,
    slug: 'confirm-existence-personal-data-processing',
  },
  AUTOMATED_DECISION_REVIEW: {
    key: LABEL_SUBJECT.AUTOMATED_DECISION_REVIEW,
    tag: SUBJECT_TAG.AUTOMATED_DECISION_REVIEW,
    slug: 'automated-decision-review',
  },
  ACCESS_MY_PERSONAL_DATA: {
    key: LABEL_SUBJECT.ACCESS_MY_PERSONAL_DATA,
    tag: SUBJECT_TAG.ACCESS_MY_PERSONAL_DATA,
    slug: 'access-my-personal-data',
  },
  CORRECTIONS_PERSONAL_DATA: {
    key: LABEL_SUBJECT.CORRECTIONS_PERSONAL_DATA,
    tag: SUBJECT_TAG.CORRECTIONS_PERSONAL_DATA,
    slug: 'corrections-personal-data',
  },
  DELETION_OF_PERSONAL_DATA: {
    key: LABEL_SUBJECT.DELETION_OF_PERSONAL_DATA,
    tag: SUBJECT_TAG.DELETION_OF_PERSONAL_DATA,
    slug: 'deletion-of-personal-data',
  },
  PERSONAL_DATA_PORTABILITY: {
    key: LABEL_SUBJECT.PERSONAL_DATA_PORTABILITY,
    tag: SUBJECT_TAG.PERSONAL_DATA_PORTABILITY,
    slug: 'personal-data-portability',
  },
  AFFILIATE_PROGRAM_HELP: {
    key: LABEL_SUBJECT.AFFILIATE_PROGRAM_HELP,
    tag: SUBJECT_TAG.AFFILIATE_PROGRAM_HELP,
    slug: 'affiliate-program-help',
  },
  REPORTS: {
    key: LABEL_SUBJECT.REPORTS,
    tag: SUBJECT_TAG.REPORTS,
    slug: 'reports',
    specifications: [SPECIFICATION.LEAD_COUNT, SPECIFICATION.LEAD_DATA],
  },
  REPORTS_PRODUCER: {
    key: LABEL_SUBJECT.REPORTS,
    tag: SUBJECT_TAG.REPORTS_PRODUCER,
    slug: 'reports',
    specifications: [SPECIFICATION.CART_ABANDONMENT, SPECIFICATION.PRODUCER_LEADS],
  },
  HOTMART_CARD_PRODUCER: {
    key: LABEL_SUBJECT.HOTMART_CARD,
    tag: SUBJECT_TAG.HOTMART_CARD_PRODUCER,
    slug: 'hotmart-card-producer',
    specifications: [
      SPECIFICATION.REQUEST_HOTMART_CARD_PRODUCER,
      SPECIFICATION.HOTMART_CARD_PROBLEMS_PRODUCER,
      SPECIFICATION.HOTMART_CARD_DOUBTS_PRODUCER,
    ],
  },
  HOTMART_CARD_AFFILIATE: {
    key: LABEL_SUBJECT.HOTMART_CARD,
    tag: SUBJECT_TAG.HOTMART_CARD_AFFILIATE,
    slug: 'hotmart-card-affiliate',
    specifications: [
      SPECIFICATION.REQUEST_HOTMART_CARD_AFFILIATE,
      SPECIFICATION.HOTMART_CARD_PROBLEMS_AFFILIATE,
      SPECIFICATION.HOTMART_CARD_DOUBTS_AFFILIATE,
    ],
  },
}
