import AuthService, { Bootstrap } from '@hotmart/cas-js'

export default (context, inject) => {
  const { app, $config } = context

  const authService = new AuthService({
    env: $config.environment,
    client_id: $config.authClientID,
    scope: 'openid profile user email address authorities phone',
    authority: $config.authorityCas,
    automaticSilentRenew: true,
    revalidateOnFocus: true,
  })

  const bootstrap = new Bootstrap(authService)

  bootstrap.subscribe({
    next: user => {
      app.store.dispatch('casUser/setUserCas', user)
    },
  })

  bootstrap.start({
    signinRedirect: false,
  })

  inject('cas', { bootstrap: bootstrap, authService: authService })
}
