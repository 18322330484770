const state = () => ({
  currentCategory: null,
})

const mutations = {
  SET_CURRENT_CATEGORY(state, category) {
    state.currentCategory = category
  },
}

const actions = {
  async fetchCategory({ commit }, id) {
    try {
      const category = await this.$categories.getSectionsCategories(id)
      if (!category.id) return

      commit('SET_CURRENT_CATEGORY', category)
    } catch (error) {
      commit('SET_CURRENT_CATEGORY', {})
    }
  },
}

const getters = {
  currentCategory: state => state.currentCategory,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
