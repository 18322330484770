export default {
  async setForm({ commit }, payload) {
    return commit('SET_FORM', payload)
  },
  async moveToNextStep({ commit, state }) {
    return commit('SET_CURRENT_STEP_INDEX', state.currentStepIndex + 1)
  },
  async moveToPreviousStep({ commit, state }) {
    return commit('SET_CURRENT_STEP_INDEX', state.currentStepIndex - 1)
  },
  async validateAndMoveToNextStep({ commit, state, getters }, selector) {
    if (getters.isValidStep(selector)) {
      return commit('SET_CURRENT_STEP_INDEX', state.currentStepIndex + 1)
    }
  },
  async resetNavigation({ commit }) {
    return commit('RESET_NAVIGATION')
  },
}
