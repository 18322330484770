export default {
  SET_SEARCH_OPEN(state, payload) {
    state.searchOpen = payload
  },

  SET_SIDEBAR_OPEN(state, payload) {
    state.sidebarOpen = payload
  },

  SET_SIDEBAR_OPEN_PROFILE(state, payload) {
    state.sidebarOpenProfile = payload
  },

  SET_ALERT_INSTABILITY(state, payload) {
    state.alertInstability = payload
  },
}
