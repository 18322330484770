import { SUMMARY } from '~/enum/form/summary'

export default {
  async setShouldRenderAISummary({ commit }, payload) {
    commit('SET_SHOULD_RENDER_AI_SUMMARY', payload)
  },
  async initAISummary({ dispatch }) {
    const retrieveToggleStatus = async toggleKey => {
      const { enabled } = await this.$toggle.retrieve(toggleKey)
      return enabled
    }

    const { subject, specification, reason } = this.$router.app.$route.query
    const path = [subject, reason, specification].filter(Boolean).join('.')

    const toggleKeysSet = new Set(Object.keys(SUMMARY))
    let enabled = false

    for (const toggle of toggleKeysSet) {
      if (SUMMARY[toggle].includes(path)) {
        const status = await retrieveToggleStatus(toggle)
        if (status) {
          enabled = true
          break
        }
      }
    }

    dispatch('setShouldRenderAISummary', enabled)
  },
}
