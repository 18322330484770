export default {
  ADD_AB_TEST(state, payload) {
    const { key, ...rest } = payload
    state.abTests = {
      ...state.abTests,
      [key]: rest,
    }
  },
  UPDATE_AB_TEST(state, payload) {
    const { key, ...rest } = payload
    state.abTests = {
      ...state.abTests,
      [key]: {
        ...state.abTests[key],
        ...rest,
      },
    }
  },
}
