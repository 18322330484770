/**
 * Waits for an element to be added to the DOM and returns it.
 * @param {string} selector - The selector of the element to wait for.
 * @example waitForElement('#my-element')
 * @returns {Promise<Element>}
 */
export default function waitForElement(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })
}
