export default {
  setIsOpenSummaryScreeningFeedbackModal({ commit }, payload) {
    commit('SET_IS_OPEN_SUMMARY_SCREENING_FEEDBACK_MODAL', payload)
  },
  setShouldOpenSummaryScreeningFeedbackModal({ commit }, payload) {
    commit('SET_SHOULD_OPEN_SUMMARY_SCREENING_FEEDBACK_MODAL', payload)
  },
  openSummaryScreeningFeedbackModal({ dispatch, getters }) {
    if (getters.shouldOpenSummaryScreeningFeedbackModal) {
      dispatch('setIsOpenSummaryScreeningFeedbackModal', true)
      return true
    }
    return false
  },
}
