import en from '@/transifex/languages/en.json'
import es from '@/transifex/languages/es.json'
import fr from '@/transifex/languages/fr.json'
import ptBR from '@/transifex/languages/pt-BR.json'

import _en from '@/transifex/overrides/en.json'
import _es from '@/transifex/overrides/es.json'
import _fr from '@/transifex/overrides/fr.json'
import _ptBr from '@/transifex/overrides/pt-BR.json'

import { get, merge, set } from 'lodash'

export const translations = { 'pt-br': ptBR, en, es, fr }

export const overridesMap = {
  'pt-br': _ptBr,
  en: _en,
  es: _es,
  fr: _fr,
}

export function getOverrides(lang) {
  const result = {}
  const originalPath = Object.keys(overridesMap[lang])
  originalPath.forEach(path => {
    const overridePath = overridesMap[lang][path]
    const newValue = get(translations[lang], overridePath)
    set(result, path, newValue)
  })
  return result
}

export function getAllOverrides() {
  const result = {}
  Object.keys(overridesMap).forEach(lang => {
    result[lang] = getOverrides(lang)
  })

  return result
}

export function getTranslationsWithOverrides() {
  return merge({}, translations, getAllOverrides())
}
