import locale from '~/store/locale'

const modules = {
  locale,
}

export default {
  namespaced: true,
  modules,
}
