import { SLUG } from '~/enum/form/constants'
import { SPECIFICATION } from '~/enum/form/specification'
import { SUBJECT } from '~/enum/form/subject'
import { TOGGLE } from '~/enum/toggles'

export const SUMMARY = {
  [TOGGLE.SUMMARY_SCREENING_MODAIS_WAVE_0]: [
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.TROUBLE_ACESSING_CONTENT.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.CANT_ACCESS_ACCOUNT.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.REFUND_OR_CANCELLATION.slug}.${SPECIFICATION.CANCEL_REFUND_REQUEST.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.BE_PRODUCER_OR_AFFILIATE.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_REGISTRATION_ACCESS.slug}.${SPECIFICATION.LOGIN_PROBLEMS.slug}`,
  ],
  [TOGGLE.SUMMARY_SCREENING_WAVE_0]: [
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.CANT_MAKE_PURCHASE.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.PAYMENT_INFORMATION.slug}.${SPECIFICATION.CREDIT_CARD.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.PAYMENT_INFORMATION.slug}.${SPECIFICATION.OTHER_PAYMENT_METHOD.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.TROUBLE_ACESSING_CONTENT.slug}`,
  ],
  [TOGGLE.SUMMARY_SCREENING_WAVE_1]: [
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_HELP_TV.slug}.${SPECIFICATION.CANT_FIND_APP.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.ACCESS_DEADLINES_QUESTIONS.slug}`,
    `${SLUG.ABOUT_HOTMART}.${SUBJECT.PROMOTE_A_PRODUCT_AT_HOTMART.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_REGISTRATION_ACCESS.slug}.${SPECIFICATION.ERROR_MESSAGE.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_REGISTRATION_ACCESS.slug}.${SPECIFICATION.NOT_RECEIVEING_EMAILS.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.NOTE_EMISSION.slug}.${SPECIFICATION.BRAZILIAN_CURRENCIE.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.REFUND_OR_CANCELLATION.slug}.${SPECIFICATION.PROOF_REFUND_REQUEST.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.HELP_MY_STUDENTS.slug}.${SPECIFICATION.HOTMART_ACCOUNT_ACCESS.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.AFFILIATE_PROGRAM_HELP.slug}`,
  ],
  [TOGGLE.SUMMARY_SCREENING_WAVE_2]: [
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.TOOLS_AFFILIATE.slug}.${SPECIFICATION.CHECKOUT_AFFILIATE.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.PRODUCT_QUESTIONS.slug}.${SPECIFICATION.SOCIAL_MEDIA_GROUPS.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.SOCIAL_MEDIA_GROUPS_QUESTIONS.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.PRODUCT_QUESTIONS.slug}.${SPECIFICATION.BONUS_OR_PHYSICAL_PRODUCT.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.SETTINGS_AND_TOOLS_AFFILIATE.slug}.${SPECIFICATION.DISCLOSURE_LINKS.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.SETTINGS_AND_TOOLS_AFFILIATE.slug}.${SPECIFICATION.EXTERNAL_PLATFORMS.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_HELP_TV.slug}.${SPECIFICATION.HTV_ERROR_MESSAGE_APPEARED.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_HELP_TV.slug}.${SPECIFICATION.HTV_CANT_ACCESS_ACCOUNT.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.HELP_MY_STUDENTS.slug}.${SPECIFICATION.CONTENT_OR_CERTIFICATE.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.ERROR_MESSAGE_APPEARED.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.BANK_ACCOUNT_REGISTRATION_PRODUCER.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.HOTLINK.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.TROUBLE_VIDEO_PLAY.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.MY_PRODUCT_CONTENT_QUESTIONS.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.PRODUCT_REVIEW.slug}.${SPECIFICATION.HELP_REGISTER_PRODUCT.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.KNOW_ACCESS_MYCOURSE.slug}`,
  ],
  [TOGGLE.SUMMARY_SCREENING_WAVE_4]: [
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_COURSE_ACCESS.slug}.${SPECIFICATION.BONUS_OR_PHYSICAL_PRODUCT_QUESTIONS.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_HELP_TV.slug}.${SPECIFICATION.CANT_FIND_APP.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.HOTMART_HELP_TV.slug}.${SPECIFICATION.ERROR_MESSAGE_APPEARED.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.CHANGE_IN_REGISTRATION_INFORMATION.slug}.${SPECIFICATION.RESET_PASSWORD.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.PAYMENT_INFORMATION.slug}.${SPECIFICATION.NOT_FOUND_PAYMENT_METHOD.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.ACCOUNT_MANAGEMENT_USER.slug}.${SPECIFICATION.AFFILIATE_PRODUCER_ERROR_MESSAGE_APPEARED.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.HELP_MY_STUDENTS.slug}.${SPECIFICATION.SMART_SUBSCRIPTIONS_PAYMENTS.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.HELP_MY_STUDENTS.slug}.${SPECIFICATION.REQUEST_UNSUBSCRIBE_PRODUCER.slug}`,
    `${SLUG.ABOUT_HOTMART}.${SUBJECT.CREATE_A_PRODUCT_AT_HOTMART.slug}`,
    `${SLUG.ABOUT_HOTMART}.${SUBJECT.BUY_A_PRODUCT_AT_HOTMART.slug}`,
    `${SLUG.ABOUT_HOTMART}.${SUBJECT.KNOW_MORE_ABOUT_HOTMART.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.BANK_ACCOUNT_REGISTRATION_AFFILIATE.slug}`,
  ],
  [TOGGLE.SUMMARY_SCREENING_WAVE_5]: [
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.CHANGE_IN_REGISTRATION_INFORMATION.slug}.${SPECIFICATION.OTHER_CHANGES.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.NOTE_EMISSION.slug}.${SPECIFICATION.OTHER_CURRENCIES.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.PAYMENT_INFORMATION.slug}.${SPECIFICATION.PIX.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.CHANGE_IN_REGISTRATION_INFORMATION.slug}.${SPECIFICATION.CHANGE_REGISTRATION_LANGUAGE.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.PAYMENT_INFORMATION.slug}.${SPECIFICATION.BILLET_BANKING.slug}`,
    `${SLUG.BOUGHT_AND_NEED_HELP}.${SUBJECT.PAYMENT_INFORMATION.slug}.${SPECIFICATION.INFO_ABOUT_SUBSCRIPTION_PAYMENT.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.TOOLS_PRODUCER.slug}.${SPECIFICATION.TRACKING_PIXEL.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.HELP_MY_STUDENTS.slug}.${SPECIFICATION.REQUEST_REFUND_PRODUCER.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.TOOLS_PRODUCER.slug}.${SPECIFICATION.HOTLINK.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.TOOLS_PRODUCER.slug}.${SPECIFICATION.HOTLEADS.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.TOOLS_PRODUCER.slug}.${SPECIFICATION.LISTBOSS.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.TOOLS_PRODUCER.slug}.${SPECIFICATION.PROMOTIONAL_MATERIAL.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.TOOLS_PRODUCER.slug}.${SPECIFICATION.MEMBERS_AREA.slug}`,
    `${SLUG.IM_A_PRODUCER_AND_I_NEED_HELP}.${SUBJECT.TOOLS_PRODUCER.slug}.${SPECIFICATION.STUDENT_REVIEWS.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.REPORTS.slug}.${SPECIFICATION.LEAD_DATA.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.TOOLS_AFFILIATE.slug}.${SPECIFICATION.TRACKING_PIXEL_AFFILIATE.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.REPORTS.slug}.${SPECIFICATION.LEAD_COUNT.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.TOOLS_AFFILIATE.slug}.${SPECIFICATION.LINK_CHECKOUT_AFFILIATE.slug}`,
    `${SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP}.${SUBJECT.TOOLS_AFFILIATE.slug}.${SPECIFICATION.LINK_SHORTENER.slug}`,
  ],
}
