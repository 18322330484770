const runtime = {
  /**
   * Public runtime config
   */
  publicRuntimeConfig: {
    environment: process.env.NODE_ENV || 'development',
    baseUrl:
      process.env.BASE_URL ||
      (process.env.LOCAL_HTTPS ? 'https://local.help.buildstaging.com:3000' : 'http://localhost:3000'),
    apiUrl: process.env.API_URL || 'https://api-help-center.buildstaging.com',
    csatUrl: process.env.API_CSAT_URL || 'https://api-csat.buildstaging.com',
    changemailUrl: process.env.API_CHANGEMAIL || 'https://api-changemail.buildstaging.com',
    abTestUrl: process.env.API_AB_TEST_URL || 'https://api-abtest.buildstaging.com/api/1',
    authorityCas:
      process.env.NODE_ENV === 'production' ? 'https://sso.hotmart.com/oidc' : 'https://sso.buildstaging.com/oidc',
    guideZendek: process.env.GUIDE_ZENDESK || 'https://suportehotmart1594843296.zendesk.com/knowledge',
    blipChatHost: process.env.BLIP_CHAT_HOST || 'https://bkf-feature.buildstaging.com/app-blip-chat/master',
    requestRefundArticleId: process.env.REQUEST_REFUND_ARTICLE_ID || '15415287106829',
    changeRequestReasonArticleId: process.env.CHANGE_REQUEST_REASON_ARTICLE_ID || '15415915663373',
    privacyFormUrl: process.env.PRIVACY_FORM_URL || 'https://app-central-privacy.buildstaging.com',
    appBW: process.env.APP_BW || 'https://app.buildstaging.com',
    apiFaqGptUrl: process.env.API_FAQ_GPT_URL || 'https://api-faqgpt-integration.buildstaging.com',
    extensionsURL: process.env.EXTENSIONS_URL || 'https://help.extensoes.hotmart.com/hc/[lang]/requests/new',
    appConsumer: process.env.APP_CONSUMER || 'https://hot-hub-ui-stg.buildstaging.com/main',
    authClientID: process.env.AUTH_CLIENT_ID || 'b06053ca-6282-4c0d-a1f3-2c472182ff63',
  },
}

export default runtime
