import { mountURL } from '@/utils/sharedHelpers'
import { LANGUAGE } from '~/enum/language'

export default async function ({ isHMR, store, route, redirect, error, params }) {
  if (isHMR) return

  const { locale } = params
  const pathSegments = route.path.split('/').filter(Boolean)

  // Find the index of the locale in the path
  const localeIndex = pathSegments.findIndex(segment => segment === locale)
  if (localeIndex === -1) {
    // Locale not found in URL, ignore this route
    return
  }

  // Get the URL parameters
  const urlParams = getUrlParams(pathSegments, localeIndex)

  if (!urlParams) {
    // Not a handled segment, ignore
    return
  }

  const { id, segment } = urlParams

  // Proceed only if 'id' is present
  if (!id) {
    return error({ statusCode: 404, message: 'This page could not be found' })
  }

  try {
    let data
    let expectedUrl

    if (segment === 'article' || segment === 'articles') {
      await store.dispatch('article/fetchArticle', id)
      data = store.getters['article/currentArticle']

      if (!data || !data.id) {
        return error({ statusCode: 404, message: 'Article not found' })
      }

      expectedUrl = mountURL('article', data.id, data.title, locale)
    } else if (segment === 'section') {
      await store.dispatch('section/fetchSection', id)
      data = store.getters['section/currentSection']

      if (!data || !data.id) {
        return error({ statusCode: 404, message: 'Section not found' })
      }

      expectedUrl = mountURL('section', data.id, data.name, locale)
    } else if (segment === 'categories') {
      await store.dispatch('categories/fetchCategory', id)
      data = store.getters['categories/currentCategory']

      if (!data || !data.id) {
        return error({ statusCode: 404, message: 'Category not found' })
      }

      expectedUrl = mountURL('categories', data.id, data.name, locale)
    }

    // Compare only the path without query strings
    if (normalizePath(route.path) !== normalizePath(expectedUrl)) {
      // Serialize the original query parameters
      const query = route.query
      const queryString = serializeQuery(query)

      // Construct the redirect URL by appending the original query string
      const redirectUrl = `${expectedUrl}${queryString}`

      return redirect(301, redirectUrl)
    }
  } catch (err) {
    console.error('Middleware Error:', err)
    return error({ statusCode: 500, message: 'Internal Server Error' })
  }
}

/**
 * Extracts resource parameters (ID and slug) from URL path segments based on the locale index.
 *
 * This function parses the URL path segments to identify the resource type (e.g., 'article', 'section'),
 * and then extracts the resource ID and slug from the subsequent segments.
 * It supports URLs where the ID and slug may be combined in a single segment (e.g., '12345-some-slug')
 * or separated into multiple segments.
 *
 * @param {string[]} pathSegments - An array of URL path segments, split by '/' and filtered to remove empty strings.
 * @param {number} localeIndex - The index of the locale segment within the pathSegments array.
 *
 * @returns {Object|null} Returns an object containing:
 *   - {string|null} id - The extracted resource ID if present; otherwise, null.
 *   - {string|null} slug - The extracted slug if present; otherwise, null.
 *   - {string} segment - The resource type segment (e.g., 'article', 'section').
 * Returns null if the resource type is not supported.
 */
function getUrlParams(pathSegments, localeIndex) {
  const idPattern = /^\d+$/
  const supportedSegments = ['article', 'articles', 'section', 'categories']

  const resourceTypeIndex = localeIndex + 1
  const resourceType = pathSegments[resourceTypeIndex]

  if (!resourceType || !supportedSegments.includes(resourceType)) {
    return null
  }

  const resourceIdentifiers = pathSegments.slice(resourceTypeIndex + 1)

  let id = null
  let slugParts = []

  if (resourceIdentifiers.length > 0) {
    const firstIdentifier = resourceIdentifiers[0]
    const match = firstIdentifier.match(/^(\d+)-(.*)$/)

    if (match) {
      id = match[1]
      if (match[2]) slugParts.push(match[2])
      slugParts.push(...resourceIdentifiers.slice(1))
    } else if (idPattern.test(firstIdentifier)) {
      id = firstIdentifier
      slugParts.push(...resourceIdentifiers.slice(1))
    } else {
      slugParts.push(...resourceIdentifiers)
    }
  }

  const slug = slugParts.length > 0 ? slugParts.join('/') : null

  return { id, slug, segment: resourceType }
}

/**
 * Helper function to serialize query parameters into a query string.
 *
 * @param {Object} query - The query parameters object.
 * @returns {string} - The serialized query string, including the leading '?' if applicable.
 */
function serializeQuery(query) {
  const keys = Object.keys(query)
  if (keys.length === 0) return ''

  const searchParams = new URLSearchParams()
  keys.forEach(key => {
    const value = query[key]
    if (Array.isArray(value)) {
      value.forEach(val => searchParams.append(key, val))
    } else if (value !== undefined && value !== null) {
      searchParams.append(key, value)
    }
  })

  const queryString = searchParams.toString()
  return queryString ? `?${queryString}` : ''
}

/**
 * Helper function to normalize paths by removing trailing slashes.
 *
 * @param {string} path - The URL path.
 * @returns {string} - The normalized path without trailing slashes.
 */
function normalizePath(path) {
  return path.endsWith('/') && path.length > 1 ? path.slice(0, -1) : path
}
