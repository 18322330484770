import * as acceptLanguageParser from 'accept-language-parser'
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE, LANGUAGE } from '~/enum/language'
class Locale {
  constructor({ app, store, route, req, params }) {
    this.app = app
    this.store = store
    this.route = route
    this.req = req
    this.params = params

    this.defaultLocale = DEFAULT_LANGUAGE
    this.fallbackLocale = FALLBACK_LANGUAGE
    this.availableLocales = Object.values(LANGUAGE)
  }

  getDefaultLocale() {
    return this.defaultLocale
  }

  getAvailableLocales() {
    return this.availableLocales
  }

  getFallbackLocale() {
    return this.fallbackLocale
  }

  getLocale() {
    const paramsLocale = this.getParamsLocale()
    if (paramsLocale) return paramsLocale

    const cookieLocale = this.getCookieLocale()
    if (cookieLocale) return cookieLocale

    const browserLanguage = this.getBrowserLanguage()
    if (browserLanguage) return browserLanguage

    const defaultLocale = this.getDefaultLocale()
    return defaultLocale
  }

  getParamsLocale() {
    const { locale } = this.route.params

    return this.getValidLocaleOrNull(locale)
  }

  getCookieLocale() {
    const locale = this.app.$cookie.get('hmLangCookie')

    return this.getValidLocaleOrNull(locale)
  }

  getBrowserLanguage() {
    if (process.server) {
      const acceptLanguage = this.req.headers['accept-language']

      if (!acceptLanguage) return null

      return this.getValidLocaleOrNull(
        acceptLanguageParser.pick(this.getAvailableLocales(), acceptLanguage, { loose: true })
      )
    }
  }

  setLocale(locale) {
    if (locale === this.store.getters['locale/getLocale']) return

    this.store.dispatch('locale/setLocale', locale)

    const path = '/'

    let sameSite = ''
    if (process.env.ENVIRONMENT === 'staging') sameSite = 'none'
    if (process.env.ENVIRONMENT === 'production') sameSite = 'none'

    let domain = null
    if (process.env.ENVIRONMENT === 'staging') domain = '.buildstaging.com'
    if (process.env.ENVIRONMENT === 'production') domain = '.hotmart.com'

    let secure = false
    if (process.env.ENVIRONMENT === 'staging') secure = true
    if (process.env.ENVIRONMENT === 'production') secure = true

    this.app.$cookie.set('hmLangCookie', locale, {
      path,
      sameSite,
      domain,
      secure,
    })

    this.app.router.afterEach(() => (this.app.i18n.locale = locale))
  }

  getValidLocaleOrNull(locale) {
    if (!locale) return null
    const localeLowerCase = locale.toLowerCase()
    if (/^pt((-|_)[A-Z]{2})?$/i.test(localeLowerCase)) return 'pt-br'
    const localeWithoutRegion = localeLowerCase.split(/[-_]/)[0]
    return this.availableLocales.includes(localeWithoutRegion) ? localeWithoutRegion : null
  }
}

export default Locale
