export default {
  canShowWelcomeMessage: ({ welcomeMessageToggle, open }) => welcomeMessageToggle && !open,
  contactUsChatOpenToggle: ({ contactUsChatOpenToggle }) => contactUsChatOpenToggle,
  hasChatbotElement: ({ element }) => !!element,
  position: ({ x, y }) => ({ x, y }),
  size: ({ width, height }) => ({ width, height }),
  isVisible: ({ visible }) => visible,
  isChatAsync: ({ chatName }) => /async/i.test(chatName),
  isChatSync: ({ chatName }) => /sync/i.test(chatName),
  isBlipChat: ({ chatName }) => /blip/i.test(chatName),
}
