import * as uuid from 'uuid'

export default {
  async executeABTest({ commit }, payload) {
    const { key, testId, condition = true } = payload
    const transactionRef = uuid.v4()
    commit('ADD_AB_TEST', {
      key,
      testId,
      inABTest: true,
      transactionRef,
    })
    if (condition) {
      const response = await this.$abtest.execute({ testId, transactionRef })

      commit('UPDATE_AB_TEST', {
        key,
        inABTest: !!response?.test,
        variant: response?.test?.candidate?.payLoad?.name,
      })
    }
  },

  async convertABTest({ state }, key) {
    const abTest = state.abTests?.[key]
    if (abTest?.inABTest) {
      const { testId, transactionRef } = abTest
      this.$abtest.convert({ testId, transactionRef })
    }
  },
}
