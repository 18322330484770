const middleware = {}

middleware['fetchDataAndRedirect'] = require('../middleware/fetchDataAndRedirect.js')
middleware['fetchDataAndRedirect'] = middleware['fetchDataAndRedirect'].default || middleware['fetchDataAndRedirect']

middleware['locale'] = require('../middleware/locale.js')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['privacyPolicyRedirect'] = require('../middleware/privacyPolicyRedirect.js')
middleware['privacyPolicyRedirect'] = middleware['privacyPolicyRedirect'].default || middleware['privacyPolicyRedirect']

export default middleware
