export default () => {
  return {
    open: false,
    welcomeMessageToggle: false,
    contactUsChatOpenToggle: false,
    element: null,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    initialized: false,
    visible: true,
    chatName: '',
  }
}
