
import { mapGetters } from 'vuex'

export default {
  layout: 'error',
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  computed: {
    ...mapGetters('casUser', ['userToken']),
  },
  mounted() {
    dataLayer.push({
      event: 'pageview',
      section: 'Help Center',
      subsection1: '404',
      subsection2: window.location.href,
      language: this.$i18n.locale,
      logged: this.$checkLogged(),
    })
    dataLayer.push({
      event: 'custom_event',
      custom_event_name: 'show_not_found_404',
      event_details: 'not_found_404',
    })
  },
}
