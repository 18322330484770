export default ctx => () => {
  const { $config, $axios } = ctx

  return {
    sendFeedback(payload) {
      const { entityId } = payload
      if (!entityId) return
      return $axios.post(`${$config.csatUrl}/v1/helpcenter`, payload)
    },
  }
}
