export default ctx => () => {
  const { $axios, store } = ctx

  return {
    async retrieve(key) {
      const response = await $axios.get(`/toggleSwitch?key=${key}`)
      store.dispatch('featureToggle/setFeatureToggle', { key, value: response.enabled })

      return response
    },
    async retrieveAll(keys) {
      const responses = await Promise.all(keys.map(key => $axios.get(`/toggleSwitch?key=${key}`)))
      const payload = Object.assign({}, ...keys.map((key, index) => ({ [key]: responses[index].enabled })))

      store.dispatch('featureToggle/setFeatureToggles', payload)

      return responses
    },
  }
}
