export default {
  SET_FORM(state, payload) {
    state.form = payload
  },
  SET_CURRENT_STEP_INDEX(state, payload) {
    state.currentStepIndex = payload
  },
  RESET_NAVIGATION(state) {
    state.form = null
    state.currentStepIndex = 0
  },
}
