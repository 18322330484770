export const CONTEXT_API_2FA = 'helpcenter/2fa'
export const ITEM_LOCAL_STORAGE = 'formRequest'
export const CAS_USER_LOCAL_STORAGE = 'cas-js:user'
export const ROUTER_STORAGE = 'signinRedirect'

export const REASONS_FORM = {
  AFFILIATE_FORM: 'AFFILIATE_FORM',
  BUYER_FORM: 'BUYER_FORM',
  DATA_PRIVACY_FORM: 'DATA_PRIVACY_FORM',
  EXTENSIONS_FORM: 'EXTENSIONS_FORM',
  NEW_USER_FORM: 'NEW_USER_FORM',
  PRODUCER_OR_CO_PRODUCER_FORM: 'PRODUCER_OR_CO_PRODUCER_FORM',
  SPARKLE_FORM: 'SPARKLE_FORM',
}

export const SLUG = {
  BOUGHT_AND_NEED_HELP: 'bought-and-need-help',
  IM_A_PRODUCER_AND_I_NEED_HELP: 'im-a-producer-and-i-need-help',
  IM_A_AFFILIATE_AND_I_NEED_HELP: 'im-a-affiliate-and-i-need-help',
  ABOUT_HOTMART: 'about-hotmart',
  HOTMART_EXTENSIONS: 'hotmart-extensions',
  PRIVACY_POLICY: 'privacy-policy',
}

export const SUBJECT_TAG = {
  AUTOMATED_DECISION_REVIEW: '__dc.form_privacy_review_of_automated_decisions__',
  BUYER_PHYSICAL_PRODUCTS: 'hc_cr_buyer-physical_products',
  REFUND_OR_CANCELLATION: 'refund_buyer',
  ACCESS_MY_PERSONAL_DATA: 'privacy_team_data_access',
  ACCOUNT_LOCKOUT_DISPUTE: 'contestacao_de_compra_sparkle',
  ACCOUNT_MANAGEMENT_USER_AFFILIATE: 'form_hotmart_account_management_affiliate_access_trouble',
  ACCOUNT_MANAGEMENT_USER: 'form_hotmart_account_management_user_access_trouble',
  AFFILIATE_PROGRAM_HELP: 'hc_cr_affiliate_affiliate_program',
  BANK_ACCOUNT_REGISTRATION_AFFILIATE: 'bank_account_affiliate',
  BANK_ACCOUNT_REGISTRATION_PRODUCER: 'bank_account_producer',
  BUSINESS_STRATEGIES: 'business_strategy_affiliate',
  BUSINESS_STRATEGIES_PRODUCER: 'hc_cr_producer_business_strategy',
  BUY_A_PRODUCT_AT_HOTMART: 'form_new_buy_a_product',
  BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE: 'hc_cr_buyer_terms_and_policy_violation',
  CAN_NOT_ACCESS_MY_ACCOUNT: 'access_sparkle',
  CANT_MAKE_PURCHASE: 'hc_cr-buyer-i_cant_make_purchase',
  CERTIFICATE_EMISSION: 'hc_cr_buyer_certificate_emission',
  CHANGE_IN_REGISTRATION_INFORMATION: 'change_email_buyer',
  CHANGING_HOTMART_EMAIL_AFFILIATE: 'change_email_affiliate',
  CHANGING_HOTMART_EMAIL_PRODUCER: 'change_email_producer',
  CONFIRM_EXISTENCE_PERSONAL_DATA_PROCESSING: 'privacy_team_existence_treatment',
  CONTENT_OR_CERTIFICATE: 'content_buyer',
  CORRECTIONS_PERSONAL_DATA: 'privacy_team_data_correction',
  CREATE_A_PRODUCT_AT_HOTMART: 'form_new_make_a_product',
  DELETE_ACCOUNT: 'account_exclusion_sparkle',
  DELETION_OF_PERSONAL_DATA: 'privacy_team_decision_review',
  HELP_MY_STUDENTS: 'help_for_my_students',
  HOTLINK: 'hotlink_affiliate',
  HOTMART_ACCOUNT_ACCESS: 'access_buyer',
  HOTMART_CARD_AFFILIATE: 'hc_cr_affiliate_hotmart_card',
  HOTMART_CARD_PRODUCER: 'hc_cr_producer_hotmart_card',
  HOTMART_COURSE_ACCESS: 'hc_cr_buyer_access_to_my_course_or_product',
  HOTMART_REGISTRATION_ACCESS: 'hc_cr_buyer_access_to_my_registration',
  HOTMART_EVENT_ACCESS: 'hc_cr_buyer_help_with_ticket_or_event',
  HOTMART_HELP_TV: 'hc_cr_buyer_help_with_hotmart_tv_app',
  IMPROPER_BILLING: 'hc_cr_buyer_refund_improper_billing',
  KNOW_MORE_ABOUT_EXTENSIONS: 'form_extensions_saber_mais',
  KNOW_MORE_ABOUT_HOTMART: 'form_new_more_about_hotmart',
  MANAGE_MY_COMMUNITY: 'community_sparkle',
  MY_WALLET: 'wallet_sparkle',
  NOTE_EMISSION: 'hc_cr_buyer_invoice_emission',
  PAYMENT_INFORMATION: 'payment_information_buyer',
  PERSONAL_DATA_PORTABILITY: 'privacy_team_data_portability',
  PROBLEM_WATCHING_SOME_CONTENT: 'video_trouble_sparkle',
  PRODUCT_QUESTIONS: 'hc_cr_buyer_product_questions',
  PRODUCT_REVIEW: 'product_review',
  PROMOTE_A_PRODUCT_AT_HOTMART: 'form_new_promote_a_product',
  REFUND_OF_A_PURCHASE: 'refund_sparkle',
  REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE: 'personal_information_change_affiliate',
  REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER: 'personal_information_change_producer',
  REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA: 'change_email_sparkle',
  REPORTS: 'hc_cr_affiliate_reports',
  REPORTS_PRODUCER: 'hc_cr_producer_reports',
  SETTINGS_AND_TOOLS_AFFILIATE: 'hc_cr_affiliate_settings',
  SETTINGS_AND_TOOLS_PRODUCER: 'hc_cr_producer_settings',
  HOTMART_RECOGNITIONS_PRODUCER: 'hc_cr_producer_awards_achieved',
  HOTMART_RECOGNITIONS_AFFILIATE: 'hc_cr_affiliate_awards_achieved',
  SPARKLE_SUBSCRIPTIONS_BONUSES: 'bonus_sparkle',
  TECHNICAL_SUPPORT: 'form_extensions_suporte_tecnico',
  BE_PRODUCER_OR_AFFILIATE: 'hc_cr_buyer_be_a_producer_or_affiliate',
  WITHDRAWALS_AND_COMMISSIONS_AFFILIATE: 'withdraw_affiliate',
  WITHDRAWALS_AND_COMMISSIONS_PRODUCER: 'withdraw_producer',
  VIOLATION_OF_TERMS_AND_POLICIES_OF_USE: 'hc_cr_affiliate_violation',
  VIOLATION_OF_PROPERTY_INTELLECTUAL: 'hc_cr_producer_intellectual_property_violation',
  TOOLS_AFFILIATE: 'hc_cr_affiliate_tools',
  TOOLS_PRODUCER: 'hc_cr_producer_tools',
  UNSUBSCRIBE: 'hc_cr_buyer_payment_unsubscribe',
}

export const SPECIFICATION_TAG = {
  ACCESS_DEADLINES_QUESTIONS: 'hc_cr_spec_pa_access_deadlines_questions',
  ACCESS_DEADLINES: 'hc_cr_spec_pq_access_deadlines',
  AFFILIATE_PRODUCER_ACCOUNT_BLOCKING_AND_REACTIVATION: 'hc_cr_spec_haa_account_blocking_and_reactivation',
  AFFILIATE_PRODUCER_CANT_ACCESS_ACCOUNT: 'hc_cr_spec_haa_i_cant_log_into_my_account',
  AFFILIATE_PRODUCER_CANT_RECEIVING_EMAIL_HOTMART: 'hc_cr_spec_haa_im_not_getting_emails_from_hotmart',
  AFFILIATE_PRODUCER_CANT_REGISTER_DOCUMENT: 'hc_cr_spec_haa_i_cant_register_my_document',
  AFFILIATE_PRODUCER_EMANCIPATE_MINOR: 'hc_cr_spec_haa_i_am_an_emancipated_minor',
  AFFILIATE_PRODUCER_ERROR_MESSAGE_APPEARED: 'hc_cr_spec_haa_im_getting_an_error_message',
  AFFILIATE_PROGRAM_PRODUCER: 'hc_cr_spec_bs_producer_affiliation',
  AFFILIATE_PROGRAM: 'tool_affiliates',
  BANK_DEBT: '__dc.form_buyers_debito_bancario__',
  BILLET_BANKING: '__dc.form_buyers_boleto_bancario__',
  BLUEPRINT_ANALYSIS: 'hc_cr_producer_product_blueprint',
  BONUS_CONTENT: 'hc_cr_spec_affiliate_tools_bonus_content_delivery',
  BONUS_OR_PHYSICAL_PRODUCT_QUESTIONS: 'hc_cr_spec_pa_bonus_and_or_physical_product_questions',
  BONUS_OR_PHYSICAL_PRODUCT: 'hc_cr_spec_pq_bonus_and_or_physical_product',
  BRAZILIAN_CURRENCIE: 'hc_cr_spec_ie_brazilian_real_currency',
  CANCEL_REFUND_REQUEST: '__dc.form_buyers_cancelar_pedido_de_reembolso__',
  CANCEL_SUBSCRIPTION: 'hc_cr_spec_buyer_payment_unsubscribe',
  CANCELED_SUBSCRIPTION_STILL_BEING_CHARGED: 'hc_cr_spec_buyer_payment_canceled_subscription_keep_charged',
  CANT_ACCESS_ACCOUNT: 'hc_cr_spec_pa_i_cant_log_into_my_account',
  CANT_FIND_APP: 'hc_cr_spec_htv_i_cant_find_the_app',
  CANT_MAKE_PURCHASE: 'hc_cr_spec_help_my_students_my_student_can_not_buy',
  CART_ABANDONMENT: 'hc_cr_spec_producer_rpt_cart_abandonment',
  CERTIFICATE_EMISSION: 'hc_cr_buyer_certificate_emission',
  CERTIFICATE: '__dc.form_buyers_certificado__',
  CHANGE_COMMISSION_BETWEEN_AFFILIATES: 'form_financial_commissions_altered_comission',
  CHANGE_COURSE_FORMAT: 'hc_cr_spec_producer_set_change_course_format',
  CHANGE_DOCUMENT_CNPJ_TO_CNPJ: 'hc-cr_spec_hotmart_account-change_cnpj_to_new_cnpj',
  CHANGE_DOCUMENT_CNPJ_TO_CPF: 'hc-cr_spec_hotmart_account-change_cnpj_to_cpf',
  CHANGE_DOCUMENT_CPF_TO_CPF: 'hc-cr_spec_hotmart_account-change_cpf_to_new_cpf',
  CHANGE_EVENT_DATE: 'hc_cr_spec_producer_set_change_event_date',
  CHANGE_IN_REGISTRATION_INFORMATION: 'hc_cr_spec_help_my_students_change_email',
  CHANGE_REGISTRATION_COUNTRY: 'form_hotmart_account_management_change_country',
  CHANGE_REGISTRATION_LANGUAGE: 'alterar_idioma_de_cadastro',
  CHANGE_REGISTRATION: 'hc-cr_spec_hotmart_account-change_cpf_to_cnpj',
  CHANGE_REQUEST_REASON: 'hc_cr_spec_bpi_update_purchase_email',
  CHANGING_HOTMART_EMAIL: 'hc_cr_spec_bpi_update_registration_email',
  CHECKIN: 'hc_cr_spec_hte_checkin',
  CHECKOUT_AFFILIATE: 'hc_cr_spec_affiliate_tools_payments_page',
  CHECKOUT: 'hc_cr_spec_tool_payment_page',
  CONTENT_DELIVERY: 'hc_cr_spec_producer_set_content_delivery',
  CONTENT_OR_CERTIFICATE: 'hc_cr_spec_help_my_students_content_or_certificate',
  COPRODUCER: 'hc_cr_spec_bs_producer_co-producer',
  CORRECT_REGISTRATION_DOCUMENTS: 'hc-cr_spec_hotmart_account-fix_registration_document',
  CREDENTIALS: 'hc_cr_spec_producer_set_credentials',
  CREDIT_CARD: '__dc.form_buyers_cartao_de_credito__',
  DISCLOSURE_LINKS: 'hc_cr_spec_affiliate_set_disclosure_links',
  DOUBTS: 'hc_cr_spec_producer_general_questions',
  EMPLOYEE_CENTER: 'hc_cr_spec_producer_set_course_collaborators',
  ERROR_MESSAGE_APPEARED: 'hc_cr_spec_pa_im_getting_an_error_message',
  ERROR_MESSAGE: 'hc_cr_spec_ra_im_getting_an_error_message',
  EXTERNAL_PLATFORMS: 'hc_cr_spec_affiliate_set_integration_with_external_platforms',
  FIND_OR_ACCESS_COURSES: 'hc_cr_spec_htv_i_cant_find_or_access_my_courses',
  FIRST_STEPS: 'hc_cr_spec_arq_affiliate_first_steps',
  GENERAL_PROBLEMS_WITH_REGISTRATION_DATA: 'form_hotmart_account_management_general',
  GENERAL_QUESTIONS_OTHER: 'hc_cr_spec_prq_producer_other',
  HELP_REGISTER_PRODUCT: 'hc_cr_producer_help_to_register_product',
  HOTLEADS_AFFILIATE: 'hc_cr_spec_affiliate_tools_lead_capture',
  HOTLEADS: 'hc_cr_spec_producer_tools_lead_capture',
  HOTLINK: 'hc_cr_spec_producer_tools_link_manager',
  HOTMART_ACCOUNT_ACCESS: 'hc_cr_spec_help_my_students_product_course_access',
  HOTMART_ADS: 'tool_ads',
  HOTMART_ASK: 'tool_ask',
  HOTMART_CARD_PROBLEMS_AFFILIATE: 'hc_cr_spec_affiliate_hotmart_card_problems',
  HOTMART_CARD_DOUBTS_AFFILIATE: 'hc_cr_spec_affiliate_hotmart_card_other_doubts',
  HOTMART_CARD_PROBLEMS_PRODUCER: 'hc_cr_spec_producer_hotmart_card_problems',
  HOTMART_CARD_DOUBTS_PRODUCER: 'hc_cr_spec_producer_hotmart_card_other_doubts',
  HOTMART_COURSE_ACCESS: 'hc_cr_buyer_access_to_my_course_or_product',
  HOTMART_EVENT_ACCESS: 'hc_cr_buyer_help_with_ticket_or_event',
  HOTMART_HELP_TV: 'hc_cr_buyer_help_with_hotmart_tv_app',
  HOTMART_ONE: 'hc_cr_spec_bs_producer_project-one',
  HOTMART_REGISTRATION_ACCESS: 'hc_cr_buyer_access_to_my_registration',
  HTV_CANT_ACCESS_ACCOUNT: 'hc_cr_spec_htv_i_cant_log_into_my_account',
  HTV_ERROR_MESSAGE_APPEARED: 'hc_cr_spec_htv_im_getting_an_error_message',
  I_CANT_WITHDRAW: 'form_financial_commissions_how_to_withdraw_comissions',
  IMAGE_VIOLATION: 'hc_cr_spec_ipv_image_violation',
  IMPORTING_STUDENTS: 'hc_cr_spec_producer_set_add_students',
  INFO_ABOUT_SUBSCRIPTION_PAYMENT: '__dc.form_buyers_pagamento_da_minha_assinatura__',
  INTEGRATIONS: 'hc_cr_spec_producer_set_integration_with_external_platforms',
  INVOICE_EMISSION: '__dc.form_buyers_emissao_de_nota_fiscal__',
  KNOW_ACCESS_MYCOURSE: 'hc_cr_spec_pa_im_not_sure_where_to_access_my_course',
  LEAD_COUNT: 'hc_cr_spec_affiliate_rpt_lead_count',
  LEAD_DATA: 'hc_cr_spec_affiliate_rpt_lead_data',
  LEGAL_PLAGIARISM: 'hc_cr_spec_ipv_piracy',
  LINK_CHECKOUT_AFFILIATE: 'hc_cr_spec_affiliate_tools_checkout_link',
  LINK_CHECKOUT: 'hc_cr_spec_tools_checkout_link',
  LINK_SHORTENER: 'hc_cr_spec_affiliate_tools_shorten_my_links',
  LISTBOSS: 'hc_cr_spec_producer_tools_sending_automatic_emails',
  LOGIN_PROBLEMS: 'hc_cr_spec_ra_i_cant_log_into_my_account',
  MARKETPLACE: 'hc_cr_spec_bs_producer_maketplace',
  MEMBERS_AREA: 'hc_cr_spec_producer_tools_bonus_content_delivery',
  MY_PRODUCT_CONTENT_QUESTIONS: 'hc_cr_spec_pa_my_product_content_questions',
  MY_PRODUCT_CONTENT: 'hc_cr_spec_pq_my_product_contents',
  MY_PRODUCT_SOCIAL_MEDIA: '__dc.form_buyers_redes_sociais_produto__',
  NOT_FOUND_PAYMENT_METHOD: 'hc_cr_spec_buyer_pmt_payment_method_not_found',
  NOT_RECEIVEING_EMAILS: 'hc_cr_spec_pa_im_not_getting_emails_from_hotmart',
  OTHER_CHANGES: 'outras_alterações',
  OTHER_CURRENCIES: 'hc_cr_spec_ie_other_currencies',
  OTHER_PAYMENT_METHOD: '__dc.form_buyers_outro_meio_de_pagamento__',
  OTHER: 'tool_others',
  PAYMENT_INFORMATION: 'hc_cr_spec_help_my_students_payment_info',
  PHYSICAL_PRODUCTS_MORE_INFORMATION_ABOUT_THE_ORDER: 'hc_cr_spec-physical_products-more_information_about_the_order',
  PHYSICAL_PRODUCTS_ORDER_DIDNT_ARRIVE: 'hc_cr_spec-physical_products-order_didnt_arrive',
  PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS: 'hc_cr_spec-physical_products-returns_and_refunds',
  PHYSICAL_PRODUCTS_TRACK_MY_ORDER: 'hc_cr_spec-physical_products-track_my_order',
  PIX: 'hc_cr_spec_payments_pix',
  PROBLEMS_WITH_MY_BALANCE: 'form_financial_commissions_account_balance',
  PRODUCER_ACHIEVEMENTS: 'hc_cr_spec_prq_producer_achievement',
  PRODUCER_CONTACT: 'hc_cr_spec_arq_affiliate_producer_contact',
  PRODUCER_LEADS: 'hc_cr_spec_producer_rpt_leads',
  PRODUCER_MEMBERS_AREA: 'hc_cr_spec_producer_set_member_area',
  PRODUCT_REGISTRATION: 'hc_cr_spec_prq_producer_product_registration',
  PRODUCT_REVIEW_LAUNCH: 'hc_cr_producer_product_analysis',
  PROMOTIONAL_MATERIAL: 'hc_cr_spec_producer_tools_promotional_material',
  PROOF_REFUND_REQUEST: 'hc_cr_buyer_refund_proof_of_refund_request',
  PROTECT_CONTENT: 'hc_cr_spec_producer_set_protect_content',
  RA_NOT_RECEIVEING_EMAILS: 'hc_cr_spec_ra_im_not_getting_emails_from_hotmart',
  REFUND_OR_CANCELLATION: 'hc_cr_spec_help_my_students_refund_or_cancellation',
  REPORTS_AND_CLICKS: 'tool_report',
  REQUEST_DUPLICATE_BILLING_BUYER: 'hc_cr_spec_buyer_refund_duplicate_billing',
  REQUEST_HOTMART_CARD_AFFILIATE: 'hc_cr_spec_affiliate_hotmart_card_request',
  REQUEST_HOTMART_CARD_PRODUCER: 'hc_cr_spec_producer_hotmart_card_request',
  REQUEST_REFUND_PRODUCER: 'hc_cr_spec_producer_refund_request_refund',
  REQUEST_REFUND: '__dc.form_buyers_solicitar_reembolso__',
  REQUEST_UNRECOGNIZED_CHARGE_BUYER: 'hc_cr_spec_buyer_refund_unrecognized_charge',
  REQUEST_UNSUBSCRIBE_PRODUCER: 'hc_cr_spec_producer_payment_unsubscribe',
  RESET_PASSWORD: '__dc.password_recover__',
  SALES_PAGE: 'hc_cr_spec_producer_set_sales_page',
  SALES_STRATEGIES: 'hc_cr_spec_arq_affiliate_sales_strategies',
  SELL_PRODUCT_PACKAGES: 'hc_cr_spec_producer_set_sell_product_packages',
  SMART_SUBSCRIPTIONS_PAYMENTS: 'hc_cr_spec_producer_pmt_smart_subscription_payment',
  SOCIAL_MEDIA_GROUPS_QUESTIONS: 'hc_cr_spec_pa_social_media_groups_or_communities_questions',
  SOCIAL_MEDIA_GROUPS: 'hc_cr_spec_pq_social_media_groups_or_communities',
  STUDENT_REVIEWS: 'hc_cr_spec_producer_tools_student_reviews',
  TRACK_REFUND: '__dc.form_buyers_acompanhar_reembolso__',
  TRACKING_PIXEL_AFFILIATE: 'hc_cr_spec_affiliate_tools_sales_tracking',
  TRACKING_PIXEL: 'hc_cr_spec_producer_tools_sales_tracking',
  TRADEMARK_VIOLATION: 'hc_cr_spec_ipv_trademark_violation',
  TRANSFER_OWNERSHIP: 'hc_cr_spec_hte_change_ownership',
  TROUBLE_ACESSING_CONTENT: 'hc_cr_spec_pa_i_cant_access_my_content',
  TROUBLE_VIDEO_PLAY: 'hc_cr_spec_pa_im_having_trouble_playing_the_video',
  UNRELEASED_PRODUCT: 'hc_cr_producer_product_not_approved',
}
export const REFUND_TAG = {
  ACCESS_DATA: 'hc_msg_havent_received_access_data',
  HIGH_COSTS: 'hc_msg_not_able_maintain_costs',
  FILE_PROBLEMS: 'hc_msg_product_files_do_not_open',
  WRONG_PASSWORD: 'hc_msg_cannot_access_with_password_provided',
  CONTACT_PRODUCER: 'hc_msg_cant_contact_producer',
  PRODUCT_QUALITY: 'hc_msg_dissatisfied_with_quality_of_product',
  PRODUCT_INCOMPLETE: 'hc_msg_product_is_incomplete',
  CANT_CONTINUE: 'hc_msg_not_be_able_to_continue_my_studies',
  DUPLICATE_PURCHASE: 'hc_msg_duplicate_purchase',
}

export const PRODUCER_REFUND_TAG = {
  PRODUCT_SALES_CLOSED: 'hc_msg_product_sales_closed',
  TECHNICAL_ISSUES: 'hc_msg_technical_issues',
  PRODUCT_DID_NOT_MEET_EXPECTATIONS: 'hc_msg_product_did_not_meet_expectations',
  CUSTOMER_NOT_SATISFIED: 'hc_msg_customer_not_satisfied',
  CUSTOMER_NOT_SET_SCHEDULE: 'hc_msg_customer_not_set_schedule',
  FINANCIAL_ISSUE: 'hc_msg_financial_issues',
  MY_SALES_STRATEGY: 'hc_msg_my_sales_strategy',
}

export const LABEL_SUBJECT = {
  HOTMART_ACCOUNT_ACCESS: 'hotmartAccountAccess',
  HOTMART_COURSE_ACCESS: 'accessMyCourseOurProduct',
  HOTMART_REGISTRATION_ACCESS: 'accessMyRegistration',
  HOTMART_EVENT_ACCESS: 'helpTicketOurEvent',
  HOTMART_HELP_TV: 'helpHotmartTv',
  CHANGE_IN_REGISTRATION_INFORMATION: 'changeInRegistrationInformation',
  HOTMART_CARD: 'hotmartCard',
  REFUND_OR_CANCELLATION: 'refundOrCancellation',
  CONTENT_OR_CERTIFICATE: 'contentOrCertificate',
  CERTIFICATE_EMISSION: 'certificateEmission',
  NOTE_EMISSION: 'noteEmission',
  BRAZILIAN_CURRENCIE: 'brazilianCurrencie',
  OTHER_CURRENCIES: 'otherCurrencies',
  MY_PRODUCT_CONTENT: 'myProductContent',
  ACCESS_DEADLINES: 'accessDeadlines',
  BONUS_OR_PHYSICAL_PRODUCT: 'bonusOrPhysicalProduct',
  SOCIAL_MEDIA_GROUPS: 'socialMediaGroups',
  PAYMENT_INFORMATION: 'paymentInformation',
  CANT_MAKE_PURCHASE: 'cantMakePurchase',
  HELP_MY_STUDENTS: 'helpMyStudents',
  CHANGING_HOTMART_EMAIL: 'changingHotmartEmail',
  CHANGE_REQUEST_REASON: 'changeRequestReason',
  PRODUCT_REVIEW: 'productReview',
  BANK_ACCOUNT_REGISTRATION: 'bankAccountRegistration',
  WITHDRAWALS_AND_COMMISSIONS: 'withdrawalsAndCommissions',
  VIOLATION_OF_TERMS_AND_POLICIES_OF_USE: 'violationOfTermsAndPoliciesOfUse',
  BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE: 'violationOfTermsAndPoliciesOfUse',
  VIOLATION_OF_PROPERTY_INTELLECTUAL: 'violationOfPropertyIntellectual',
  SETTINGS_AND_TOOLS: 'settingsAndTools',
  BUSINESS_STRATEGIES: 'businessStrategies',
  HOTLINK: 'hotlink',
  IMPROPER_BILLING: 'improperBilling',
  BUY_APRODUCT_AT_HOTMART: 'buyAproductAtHotmart',
  CREATE_APRODUCT_AT_HOTMART: 'createAproductAtHotmart',
  PROMOTE_APRODUCT_AT_HOTMART: 'promoteAproductAtHotmart',
  KNOW_MORE_ABOUT_HOTMART: 'knowMoreAboutHotmart',
  ACCOUNT_LOCKOUT_DISPUTE: 'account_lockout_dispute',
  CAN_NOT_ACCESS_MY_ACCOUNT: 'canNotAccessMyAccount',
  REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA: 'registrationAndModificationOfPersonalData',
  MANAGE_MY_COMMUNITY: 'manageMyCommunity',
  SPARKLE_SUBSCRIPTIONS_BONUSES: 'premiumBonusCommunities',
  MY_WALLET: 'myWallet',
  PROBLEM_WATCHING_SOME_CONTENT: 'problemWatchingSomeContent',
  REFUND_OF_APURCHASE: 'refundOfApurchase',
  DELETE_ACCOUNT: 'deleteAccount',
  KNOW_MORE_ABOUT_EXTENSIONS: 'knowMoreAboutExtensions',
  TECHNICAL_SUPPORT: 'technicalSupport',
  TOOLS: 'tools',
  HOTMART_RECOGNITIONS_PRODUCER: 'hotmart_recognitions',
  HOTMART_RECOGNITIONS_AFFILIATE: 'hotmart_recognitions',
  CONFIRM_EXISTENCE_PERSONAL_DATA_PROCESSING: 'confirmExistencePersonalDataProcessing',
  AUTOMATED_DECISION_REVIEW: 'automatedDecisionReview',
  ACCESS_MY_PERSONAL_DATA: 'accessMyPersonalData',
  CORRECTIONS_PERSONAL_DATA: 'correctionsPersonalData',
  DELETION_OF_PERSONAL_DATA: 'deletionOfPersonalData',
  PERSONAL_DATA_PORTABILITY: 'personalDataPortability',
  PRODUCT_QUESTIONS: 'productQuestions',
  BE_PRODUCER_OR_AFFILIATE: 'beProducerOrAffiliate',
  AFFILIATE_PROGRAM_HELP: 'affiliateProgramHelp',
  REPORTS: 'reports',
  UNSUBSCRIBE: 'unsubscribe',
}

export const DATA_LOCAL_STORAGE = {
  COUNTRY: 'country',
  FORM_REQUEST: 'formRequest',
  HOTLINK: 'hotlink',
  HOW_DID_YOU_KNOW: 'howDidYouKnow',
  ID_PRODUCT: 'productId',
  LANGUAGE: 'language',
  OPERATIONAL_SYSTEM: 'operationalSystem',
  REASON: 'reason',
}
