/**
 * This is a navigation guard plugin that will be called before each route change.
 * @see {@link https://router.vuejs.org/guide/advanced/navigation-guards.html}
 */
export default function ({ app }) {
  app.router.beforeEach(async (_, from, next) => {
    if (
      /^locale-contact-us-slug$/.test(from.name) &&
      (await app.store.dispatch('summaryScreeningFeedbackModal/openSummaryScreeningFeedbackModal'))
    ) {
      return false
    }

    next()
  })
}
