import { LANGUAGE } from '~/enum/language'

export default () => {
  return {
    locale: LANGUAGE.ENGLISH,
    locales: [
      { name: 'Português - Brasil', code: LANGUAGE.PORTUGUESE },
      { name: 'English', code: LANGUAGE.ENGLISH },
      { name: 'Español', code: LANGUAGE.SPANISH },
      { name: 'Français', code: LANGUAGE.FRENCH },
    ],
  }
}
