export default {
  SET_FEATURE_TOGGLE(state, { key, value }) {
    state[key] = value
  },
  SET_FEATURE_TOGGLES(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state[key] = value
    })
  },
}
