export default {
  SET_OPEN(state, payload) {
    state.open = payload
  },
  SET_WELCOME_MESSAGE_TOGGLE(state, payload) {
    state.welcomeMessageToggle = payload
  },
  SET_CONTACT_US_CHAT_OPEN_TOGGLE(state, payload) {
    state.contactUsChatOpenToggle = payload
  },
  SET_ELEMENT(state, payload) {
    state.element = payload
  },
  SET_X(state, payload) {
    state.x = payload
  },
  SET_Y(state, payload) {
    state.y = payload
  },
  SET_WIDTH(state, payload) {
    state.width = payload
  },
  SET_HEIGHT(state, payload) {
    state.height = payload
  },
  SET_INITIALIZED(state, payload) {
    state.initialized = payload
  },
  SET_VISIBLE(state, payload) {
    state.visible = payload
  },
  SET_CHAT_NAME(state, payload) {
    state.chatName = payload
  },
}
