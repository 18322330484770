
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('casUser', ['userCas']),
  },
  mounted() {
    this.$microfrontRenderer()
  },
}
