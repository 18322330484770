import LocaleService from '@/services/LocaleService'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getTranslationsWithOverrides } from './override'

class LocalePlugin {
  constructor({ app, store, route, req, params }) {
    this.app = app

    this.localeService = new LocaleService({ app, store, route, req, params })

    this.handle()
  }

  handle() {
    const locale = this.localeService.getLocale()
    const fallbackLocale = this.localeService.getFallbackLocale()
    const messages = getTranslationsWithOverrides()

    this.app.i18n = new VueI18n({
      locale,
      fallbackLocale,
      messages,
      silentTranslationWarn: true,
    })

    this.app.i18n.path = path => `/${locale}/${path}`

    this.localeService.setLocale(locale)
  }
}

Vue.use(VueI18n)

export default ({ isHMR, app, store, route, req, params }, inject) => {
  if (isHMR) return

  const instance = new LocalePlugin({ app, store, route, req, params })

  inject('t', function (...args) {
    return instance.app.i18n.t(...args)
  })
}
