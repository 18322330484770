import cookie from 'cookie'

class UniversalCookie {
  constructor(req = {}, res = {}) {
    this.req = req
    this.res = res
  }

  getResponseCookies() {
    let cookies = this.res.getHeader('Set-Cookie')
    cookies = typeof cookies === 'string' ? [cookies] : cookies

    return cookies || []
  }

  setResponseCookie(cookieList) {
    this.res.setHeader('Set-Cookie', cookieList)
  }

  getHeaders() {
    if (process.server) return this.req.headers.cookie || ''
    if (process.client) return document.cookie || ''
  }

  parseToJSON(value) {
    try {
      return JSON.parse(value)
    } catch (err) {
      return value
    }
  }

  set(name, value, options) {
    value = typeof value === 'object' ? JSON.stringify(value) : value

    if (process.server) {
      const cookies = this.getResponseCookies()

      cookies.push(cookie.serialize(name, value, options))

      this.setResponseCookie(cookies)
    } else {
      document.cookie = cookie.serialize(name, value, options)
    }
  }

  get(name) {
    const cookies = cookie.parse(this.getHeaders())

    return this.parseToJSON(cookies[name])
  }

  remove(name, options) {
    options.expires = new Date(0)

    if (this.get(name)) this.set(name, '', options)
  }
}

export default ({ req, res }, inject) => {
  inject('cookie', new UniversalCookie(req, res))
}
