import { CAS_USER_LOCAL_STORAGE } from '~/enum/form/constants'

export default (_, inject) => {
  function checkLogged() {
    const user = JSON.parse(localStorage.getItem(CAS_USER_LOCAL_STORAGE))

    return Boolean(user)
  }

  inject('checkLogged', checkLogged)
}
