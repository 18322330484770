import { REASONS_FORM, SLUG } from '~/enum/form/constants'
import { SUBJECT } from '~/enum/form/subject'

const formOptions = [
  {
    reason: REASONS_FORM.BUYER_FORM,
    slug: SLUG.BOUGHT_AND_NEED_HELP,
    subjects: [
      SUBJECT.CANT_MAKE_PURCHASE,
      SUBJECT.HOTMART_EVENT_ACCESS,
      SUBJECT.HOTMART_HELP_TV,
      SUBJECT.CHANGE_IN_REGISTRATION_INFORMATION,
      SUBJECT.HOTMART_COURSE_ACCESS,
      SUBJECT.HOTMART_REGISTRATION_ACCESS,
      SUBJECT.UNSUBSCRIBE,
      SUBJECT.IMPROPER_BILLING,
      SUBJECT.REFUND_OR_CANCELLATION,
      SUBJECT.CERTIFICATE_EMISSION,
      SUBJECT.NOTE_EMISSION,
      SUBJECT.BUYER_PHYSICAL_PRODUCTS,
      SUBJECT.PRODUCT_QUESTIONS,
      SUBJECT.PAYMENT_INFORMATION,
      SUBJECT.BE_PRODUCER_OR_AFFILIATE,
      SUBJECT.BUYER_VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
    ],
  },
  {
    reason: REASONS_FORM.PRODUCER_OR_CO_PRODUCER_FORM,
    slug: SLUG.IM_A_PRODUCER_AND_I_NEED_HELP,
    subjects: [
      SUBJECT.ACCOUNT_MANAGEMENT_USER,
      SUBJECT.HELP_MY_STUDENTS,
      SUBJECT.CHANGING_HOTMART_EMAIL_PRODUCER,
      SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_PRODUCER,
      SUBJECT.PRODUCT_REVIEW,
      SUBJECT.BANK_ACCOUNT_REGISTRATION_PRODUCER,
      SUBJECT.HOTMART_CARD_PRODUCER,
      SUBJECT.SETTINGS_AND_TOOLS_PRODUCER,
      SUBJECT.BUSINESS_STRATEGIES_PRODUCER,
      SUBJECT.HOTMART_RECOGNITIONS_PRODUCER,
      SUBJECT.TOOLS_PRODUCER,
      SUBJECT.REPORTS_PRODUCER,
      SUBJECT.WITHDRAWALS_AND_COMMISSIONS_PRODUCER,
      SUBJECT.VIOLATION_OF_PROPERTY_INTELLECTUAL,
    ],
  },
  {
    reason: REASONS_FORM.AFFILIATE_FORM,
    slug: SLUG.IM_A_AFFILIATE_AND_I_NEED_HELP,
    subjects: [
      SUBJECT.CHANGING_HOTMART_EMAIL_AFFILIATE,
      SUBJECT.ACCOUNT_MANAGEMENT_USER_AFFILIATE,
      SUBJECT.REGISTRATION_AND_MODIFICATION_OF_PERSONAL_DATA_AFFILIATE,
      SUBJECT.BANK_ACCOUNT_REGISTRATION_AFFILIATE,
      SUBJECT.HOTMART_CARD_AFFILIATE,
      SUBJECT.SETTINGS_AND_TOOLS_AFFILIATE,
      SUBJECT.BUSINESS_STRATEGIES,
      SUBJECT.HOTMART_RECOGNITIONS_AFFILIATE,
      SUBJECT.TOOLS_AFFILIATE,
      SUBJECT.HOTLINK,
      SUBJECT.AFFILIATE_PROGRAM_HELP,
      SUBJECT.REPORTS,
      SUBJECT.WITHDRAWALS_AND_COMMISSIONS_AFFILIATE,
      SUBJECT.VIOLATION_OF_TERMS_AND_POLICIES_OF_USE,
    ],
  },
  {
    reason: REASONS_FORM.NEW_USER_FORM,
    slug: SLUG.ABOUT_HOTMART,
    subjects: [
      SUBJECT.BUY_A_PRODUCT_AT_HOTMART,
      SUBJECT.CREATE_A_PRODUCT_AT_HOTMART,
      SUBJECT.PROMOTE_A_PRODUCT_AT_HOTMART,
      SUBJECT.KNOW_MORE_ABOUT_HOTMART,
    ],
  },
  {
    reason: REASONS_FORM.EXTENSIONS_FORM,
    slug: SLUG.HOTMART_EXTENSIONS,
    subjects: [SUBJECT.KNOW_MORE_ABOUT_EXTENSIONS, SUBJECT.TECHNICAL_SUPPORT],
  },
  {
    reason: REASONS_FORM.DATA_PRIVACY_FORM,
    slug: SLUG.PRIVACY_POLICY,
    subjects: [
      SUBJECT.AUTOMATED_DECISION_REVIEW,
      SUBJECT.CONFIRM_EXISTENCE_PERSONAL_DATA_PROCESSING,
      SUBJECT.ACCESS_MY_PERSONAL_DATA,
      SUBJECT.CORRECTIONS_PERSONAL_DATA,
      SUBJECT.DELETION_OF_PERSONAL_DATA,
      SUBJECT.PERSONAL_DATA_PORTABILITY,
    ],
  },
]

const locations = [
  'country_brazil',
  'country_argentina',
  'country_bolivia',
  'country_chile',
  'country_colombia',
  'country_dominican_republic',
  'country_ecuador',
  'country_france',
  'country_italy',
  'country_japan',
  'country_mexico',
  'country_mozambique',
  'country_netherlands',
  'country_panama',
  'country_peru',
  'country_portugal',
  'country_spain',
  'country_united_kingdom',
  'country_united_states',
  'country_uruguay',
  'region_africa',
  'region_asia',
  'region_central_america',
  'region_europe',
  'region_north_america',
  'region_oceania',
  'region_south_america',
]
const bkgCategory = [
  {
    id: 360003206452,
    imgDefault: '/images/ilustrations/affiliateHelpInternal.svg',
    imgJA: '/images/ilustrations/affiliateHelpInternalJa.svg',
    imgAR: '/images/ilustrations/affiliateHelpInternalAr.svg',
  },
  {
    id: 360003206472,
    imgDefault: '/images/ilustrations/producerHelpInternal.svg',
    imgJA: '/images/ilustrations/producerHelpInternalJa.svg',
    imgAR: '/images/ilustrations/producerHelpInternalAr.svg',
  },
  {
    id: 15412842449421,
    imgDefault: '/images/ilustrations/personasHelpInternal.svg',
    imgJA: '/images/ilustrations/personasHelpInternal.svg',
    imgAR: '/images/ilustrations/personasHelpInternal.svg',
  },
  {
    id: 15412867835789,
    imgDefault: '/images/ilustrations/personasHelpInternal.svg',
    imgJA: '/images/ilustrations/personasHelpInternal.svg',
    imgAR: '/images/ilustrations/personasHelpInternal.svg',
  },
  {
    id: 25851725931533,
    imgDefault: '/images/ilustrations/personasHelpInternal.svg',
    imgJA: '/images/ilustrations/personasHelpInternal.svg',
    imgAR: '/images/ilustrations/personasHelpInternal.svg',
  },
  {
    id: 25851777677453,
    imgDefault: '/images/ilustrations/personasHelpInternal.svg',
    imgJA: '/images/ilustrations/personasHelpInternal.svg',
    imgAR: '/images/ilustrations/personasHelpInternal.svg',
  },
  {
    id: 360003231591,
    imgDefault: '/images/ilustrations/buyersHelpInternal.svg',
    imgJA: '/images/ilustrations/buyersHelpInternalJa.svg',
    imgAR: '/images/ilustrations/buyersHelpInternalAr.svg',
  },
  {
    id: 360002523332,
    imgDefault: '/images/ilustrations/knowMoreInternal.svg',
    imgJA: '/images/ilustrations/knowMoreInternalJa.svg',
    imgAR: '/images/ilustrations/knowMoreInternalAr.svg',
  },
]

const languages = ['portuguesBr', 'espanhol', 'ingles', 'arabe', 'frances', 'italiano', 'japones']

const operationalSystem = ['android', 'ios']

export { bkgCategory, formOptions, languages, locations, operationalSystem }
