export default $axios => () => ({
  getSearchArticle(query, numberPage) {
    const page = numberPage ? `&page=${numberPage - 1}` : ''

    return $axios.get(`/articles/v2?&query=${query}${page}`)
  },
  getSuggestedArticles(tags) {
    const suffixUrl = tags.reduce((accumulator, currentValue) => accumulator + '&labels=' + currentValue, '')
    return $axios.get(`/articles/featured?${suffixUrl}`)
  },
  getArticle(id) {
    return $axios.get(`/articles/${id}`)
  },
  getArticlesSection({ id, page }) {
    return $axios.get(`/articles/section/${id}?page=${page}`)
  },

  getArticleRelated({ id, size }) {
    const sizeParam = `?size=${size}`

    return $axios.get(`/articles/${id}/related${sizeParam}`)
  },
})
