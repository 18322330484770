const state = () => ({
  currentSection: null,
})

const mutations = {
  SET_CURRENT_SECTION(state, section) {
    state.currentSection = section
  },
}

const actions = {
  async fetchSection({ commit }, id) {
    try {
      const section = await this.$section.getSectionArticles(id)
      if (!section.id) return

      commit('SET_CURRENT_SECTION', section)
    } catch (error) {
      commit('SET_CURRENT_SECTION', {})
    }
  },
}

const getters = {
  currentSection: state => state.currentSection,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
