import microfrontRenderer from '@hotmart/microfront-renderer'

const ON_CHAT_TOGGLE = 'app-chat:on-toggle'
const ON_CHAT_INIT = 'app-chat:on-init'
const ON_CHAT_OPEN = 'app-chat:on-open'

export default ({ app, $config }, inject) => {
  const handleChatToggle = ({ detail: { open } }) => {
    dataLayer.push({
      event: 'custom_event',
      custom_event_name: 'click_chatbot',
      event_details: open ? 'click_open' : 'click_close',
    })
  }
  const handleChatInit = async ({ detail: { chatName } }) => {
    await app.store.dispatch('chatbot/setChatName', chatName)
  }
  const handleChatOpen = () => {
    const isChatAsync = app.store.getters['chatbot/isChatAsync']

    if (!isChatAsync) {
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_widget_chat_sync',
        event_details: 'Click::OpenWidgetHC',
      })
    }
  }
  const addChatListeners = () => {
    if (typeof window !== 'undefined') {
      document.addEventListener(ON_CHAT_TOGGLE, handleChatToggle)
      document.addEventListener(ON_CHAT_INIT, handleChatInit)
      document.addEventListener(ON_CHAT_OPEN, handleChatOpen)
    }
  }
  addChatListeners()
  inject('microfrontRenderer', () => {
    const userId = app.store.getters['casUser/userCas']?.profile?.id
    const url = $config.blipChatHost
    microfrontRenderer({
      url: `${url}/index.bundle.js`,
      globalName: '@hotmart/app-blip-chat',
      containerId: 'app-blip-chat',
      props: {
        baseUrl: url,
        language: app.i18n.locale,
        userId,
        gestAccess: !userId,
        domain: 'HELPCENTER',
      },
    })
  })
}
