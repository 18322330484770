export default ctx => () => {
  const { $config, $axios } = ctx

  return {
    execute({ testId, transactionRef }) {
      return $axios.post(`${$config.abTestUrl}/test/${testId}/execute?transactionRef=${transactionRef}`)
    },

    convert({ testId, transactionRef }) {
      return $axios.post(`${$config.abTestUrl}/test/${testId}/convert/${transactionRef}`)
    },
  }
}
