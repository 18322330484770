export default ctx => () => {
  const { $axios, $config } = ctx

  return {
    async getArticles(streamingId) {
      return $axios.get(`${$config.apiFaqGptUrl}/stream/summary/${streamingId}/articles`)
    },
    async getSummary(query, format) {
      return $axios.get(`${$config.apiFaqGptUrl}/summary?query=${query}&format=${format}`)
    },
    async getSummaryScreening({ username, message, subject, reason, specification }, format = 'html') {
      return $axios.post(`${$config.apiFaqGptUrl}/summary/screening?format=${format}`, {
        username,
        message,
        subject,
        reason,
        specification,
      })
    },
  }
}
